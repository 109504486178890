<template>
  <div v-loading.fullscreen.lock='loading' element-loading-text="拼命加载中" element-loading-background="rgba(255, 255, 255, 0.5)">
     <el-select v-model="pageInfo.profileId" style="margin-bottom:15px;width:150px;margin-right:10px" placeholder="请选择店铺">
        <el-option-group
          v-for="group in shopId"
          :key="group.id"
          :label="group.name">
          <el-option
              v-for="item in group.marketPlaceTree"
              :key="item.profileId"
              :label="group.name+'-'+item.name"
              :value="item.profileId"
              @click.native='getID(item.profileId,item.currency,group.id,item.id)'>{{ item.name }}
          </el-option>
      </el-option-group>
    </el-select>
    <TimeQuantum style="margin-right:10px;" section='近7天' @selectFinish='getTimeSection' @sectionFinish='getDate'></TimeQuantum>
    <el-select clearable @clear="screenedCampaignList=campaignList" v-model="pageInfo.portfolioId" style="width:150px;margin-right:10px" filterable placeholder="请选择组合" @change="find">
        <el-option
        v-for="item in portfolioList"
        :key="item.id"
        :label="item.value"
        :value="item.id"
        @click.native="getCampaignList(item.id)">
        </el-option>
    </el-select>
    <el-select clearable v-model="pageInfo.campaignId" style="width:150px;margin-right:10px" filterable placeholder="选择广告活动" @change="find">
        <el-option
        v-for="item in screenedCampaignList"
        :key="item.id"
        :label="item.value"
        :value="item.id"
        @click.native="getPortfolioId(item.portfolioId,item.id)">
        <p>
            <span>({{item.type}}) </span>
            <span v-if="item.state=='enabled'" style="color:#1AFA29" class="iconfont icon-duihao"></span>
            <span v-if="item.state=='paused'" style="font-size:14px;color:red" class="iconfont icon-zantingtingzhi"></span>
            <span v-if="item.state=='archived'" style="font-size:14px;color:#DCDFE6" class="iconfont icon-guidang"></span>
            <span> {{item.value}}</span>
        </p>
        </el-option>
    </el-select>
    <el-select clearable v-model="pageInfo.groupsState" style="width:150px;margin-right:10px" filterable placeholder="广告组状态" @change="find">
        <el-option
        v-for="item in adGroupStateList"
        :key="item.id"
        :label="item.value"
        :value="item.id">
        </el-option>
    </el-select>
    <el-select clearable v-model="pageInfo.campaignStatus" style="width:150px;margin-right:10px" filterable placeholder="广告活动状态" @change="find">
        <el-option
        v-for="item in campaignStateList"
        :key="item.id"
        :label="item.value"
        :value="item.id">
        </el-option>
    </el-select>
    <el-select clearable v-model="pageInfo.targetType" style="width:150px;margin-right:10px"   filterable placeholder="所有投放" @change="find">
        <el-option
        v-for="item in adGroupTargetTypeList"
        :key="item.id"
        :label="item.value"
        :value="item.id">
        </el-option>
    </el-select>
    <el-select clearable v-model="pageInfo.isFavorite" style="width:150px;margin-right:10px"   filterable placeholder="关注状态" @change="find">
        <el-option
        v-for="item in favoriteTypeList"
        :key="item.id"
        :label="item.value"
        :value="item.id">
        </el-option>
    </el-select>
    <el-input clearable v-model="pageInfo.keyword" style="width:150px;margin-right:10px" placeholder="输入广告组名称" @keyup.enter.native = "find"></el-input>
    <el-button @click="find"  type="primary">立即查询</el-button>
    <el-button type="primary" style="margin-right:10px" @click="add">增加广告组</el-button>
    <el-dropdown  trigger='click' style="margin-right:10px">
        <el-button type="primary">
            批量操作<i class="el-icon-arrow-down el-icon--right"></i>
        </el-button>
        <el-dropdown-menu slot="dropdown">
            <el-dropdown-item
            v-for="(item,index) in operateTypeList"
            :key='item.id'
            @click.native="batch(item.id,item.values)">{{item.values}}</el-dropdown-item>
        </el-dropdown-menu>
    </el-dropdown>
    <tableDialog style="float:right" @setTableHead='setHead' :tableList='headList'></tableDialog>
    <el-table
    :data="tableData"
    @sort-change="sortTable"
    style="width: 100%;margin-top:10px"
    @selection-change="handleSelectionChange"
    :cell-style="{ fontSize: '13px', padding: '10px 0',textAlign:'center' }"
    :header-cell-style="{
        fontSize: '13px',
        padding: '9px 0',
        marginTop: '10px',
        textAlign:'center',
        background:'#EBEEF5'
    }">
        <el-table-column
        type="selection"
        :selectable="selectable"
        width="45"
        fixed="left">
        </el-table-column>
        <el-table-column
            prop="name"
            label="广告组"
            width="200"
            fixed="left">
            <template slot-scope="scope">
                <p style="text-align:left;display:flex;align-items:center">
                    <span @click="focusChange(scope.row.id,scope.row.focusOn)" :class="scope.row.focusOn==0?'el-icon-star-off':'el-icon-star-on'" style="font-size:17px;margin-right:10px"></span>
                    <span>{{scope.row.name}}</span>
                </p>
            </template>
        </el-table-column>
        <el-table-column
            prop="prop"
            label="启停/分析/更改"
            width="170"
            fixed="left">
            <template slot-scope="scope">
                <div style="display:flex;justify-content:space-around;align-items:center;padding:0 20px">
                    <el-switch 
                    v-if="scope.row.state!='archived'"
                    v-model="scope.row.state"
                    active-color="#13ce66" 
                    inactive-color="#C1C1C1"
                    active-value="enabled"
                    inactive-value="paused"
                    @change="changeStatus(scope.row.adGroupId,scope.row.state,scope.$index)"
                    ></el-switch>
                    <el-link v-else-if="scope.row.state=='archived'" disabled class="el-icon-document" style="font-size:25px;margin-right:10px"></el-link>
                    <el-tooltip effect="dark" placement="top" content="查看历史趋势">
                      <el-link class="iconfont icon-jingyingfenxi" style="font-size:24px" @click="getChart(scope.row)"></el-link>
                    </el-tooltip>
                    <el-link v-if="scope.row.state!='archived'" class="el-icon-setting" style="font-size:23px" @click="getUpdateInit(scope.row.id,scope.row.targetTypeName,scope.row.campaignId,scope.row.adGroupId)"></el-link>
                </div>
            </template>
        </el-table-column>
        <el-table-column
            header-align="center"
            align="center"
            :prop="item.prop"
            :sortable="item.sortable?'custom':false"
            label="label" 
            v-for="item in tableHead"
            :key='item.name'
            :min-width="item.width">
            <template slot="header">
                <span v-if="item.name=='定时策略'">
                    <span>{{item.name}}</span>
                    <el-tooltip placement="top">
                        <div slot="content" style="line-height:20px;min-width:150px;max-width:420px">
                            <p>广告对象定时策略绑定情况。</p>
                            <p>悬停策略类型名称上，可以查看策略的名称、状态、生效状态和应用策略状态。</p>
                            <p>点击策略类型名称，可以查看策略的详情、启停应用状态和修改分时计算基准值。</p>
                            <p>策略类型名称如果是<span style="color:#BF8350">灰色字体，表示策略当前处于不被执行状态</span>（策略未生效、策略被暂停、对象暂停应用了策略）。</p>
                        </div>
                        <span class="el-icon-question"></span>
                    </el-tooltip>
                </span>
                <span v-else-if="item.name=='建议竞价'">
                    <span>{{item.name}}</span>
                    <el-tooltip placement="top">
                        <div slot="content" style="line-height:20px;min-width:150px;max-width:420px">
                            <p>建议竞价是为您量身定制的，有助于您的关键词赢得展示次数。</p>
                            <p>建议竞价范围基于与您的广告类似且在过去 7 天内赢得了展示次数的广告的竞价。 <a style="color:#13CE66" target="top" href="https://advertising.amazon.com/help/ref=ams_head_help?entityId=ENTITY3VAZBWP88M62S#GYQY2B3R58ZHSHJJ">了解更多信息</a></p><br>
                            <p>自动投放未归档的广告组、未归档的关键词和商品定位可以获取建议竞价。</p>
                        </div>
                        <span class="el-icon-question"></span>
                    </el-tooltip>
                </span>
                <span v-else-if="item.name=='竞价'">
                    <span>{{item.name}}</span>
                    <el-tooltip placement="top">
                        <div slot="content" style="line-height:20px;min-width:150px;max-width:350px">
                            <p>当关键词触发您的广告时，您需要为点击支付的金额。 <a style="color:#13CE66" href="https://advertising.amazon.com/help?entityId=ENTITY3VAZBWP88M62S#GTX8JYBTJX5EUCZW" target="top">了解更多信息</a></p>
                        </div>
                        <span class="el-icon-question"></span>
                    </el-tooltip>
                </span>
                <span v-else-if="item.name=='曝光量'">
                    <span>{{item.name}}</span>
                    <el-tooltip placement="top">
                        <div slot="content" style="line-height:20px;min-width:150px;max-width:220px">
                            <p>广告投放给买家的展示总数。</p><br>
                            <p>没有曝光量就无法带来点击及购买转化，因此整体预算内，曝光量越高越好。</p>
                        </div>
                        <span class="el-icon-question"></span>
                    </el-tooltip>
                </span>
                <span v-else-if="item.name=='点击量'">
                    <span>{{item.name}}</span>
                    <el-tooltip placement="top">
                        <div slot="content" style="line-height:20px;min-width:150px;max-width:350px">
                            <p>点击量：广告被点击的总次数。</p>
                            <p>点击率：每一次曝光被点击的概率。</p><br>
                            <p>计算规则：广告点击率 = 点击量 / 曝光量 * 100%；</p><br>
                            <p>亚马逊广告点击率一般在0.6%-2.5%左右。</p>
                            <p>点击率高：说明选词匹配度越高，同时您的商品标题、主图、价格、评论数量，较好的吸引用户点击。</p>
                            <p>点击率低：建议对选词，商品标题、主图、价格、评论数量进行优化。</p>
                        </div>
                        <span class="el-icon-question"></span>
                    </el-tooltip>
                </span>
                <span v-else-if="item.name=='点击率'">
                    <span>{{item.name}}</span>
                    <el-tooltip placement="top">
                        <div slot="content" style="line-height:20px;min-width:150px;max-width:350px">
                            <p>点击量：广告被点击的总次数。</p>
                            <p>点击率：每一次曝光被点击的概率。</p><br>
                            <p>计算规则：广告点击率 = 点击量 / 曝光量 * 100%；</p><br>
                            <p>亚马逊广告点击率一般在0.6%-2.5%左右。</p>
                            <p>点击率高：说明选词匹配度越高，同时您的商品标题、主图、价格、评论数量，较好的吸引用户点击。</p>
                            <p>点击率低：建议对选词，商品标题、主图、价格、评论数量进行优化。</p>
                        </div>
                        <span class="el-icon-question"></span>
                    </el-tooltip>
                </span>
                <span v-else-if="item.name=='花费'">
                    <span>{{item.name}}</span>
                    <el-tooltip placement="top">
                        <div slot="content" style="line-height:20px;min-width:150px;max-width:350px">
                            <p>广告被点击，所产生的总费用，相当于您的广告总成本 。</p><br>
                            <p>1、因近30天（尤其近3天）亚马逊接口返回的数据，可能与亚马逊控制台展现的数据存在略微不一致，因此可能导致系统统计结果与亚马逊控制台展现的数据存在略微不一致；</p><br>
                            <p>2、以商品和站点不同维度查看数据，因数据来自亚马逊不同的接口，不同接口的请求时间或者报告生成时间可能不一致，因此可能导致两个维度下统计结果存在略微不一致；</p>
                            <p>3、计算规则：广告花费 = CPC竞价 * 被点击次数；</p>
                        </div>
                        <span class="el-icon-question"></span>
                    </el-tooltip>
                </span>
                <span v-else-if="item.name=='CPC均价'">
                    <span>{{item.name}}</span>
                    <el-tooltip placement="top">
                        <div slot="content" style="line-height:20px;min-width:150px;max-width:350px">
                            <p>CPC点击的平均价格。</p>
                        </div>
                        <span class="el-icon-question"></span>
                    </el-tooltip>
                </span>
                <span v-else-if="item.name=='订单数'">
                    <span>{{item.name}}</span>
                    <el-tooltip placement="top">
                        <div slot="content" style="line-height:20px;min-width:150px;max-width:350px">
                            <p>通过广告带来的订单量。</p><br>
                            <p>1、因近30天（尤其近3天）亚马逊接口返回的数据，可能与亚马逊控制台展现的数据存在略微不一致，因此可能导致系统统计结果与亚马逊控制台展现的数据存在略微不一致；</p>
                            <p>2、以商品和站点不同维度查看数据，因数据来自亚马逊不同的接口，不同接口的请求时间或者报告生成时间可能不一致，因此可能导致两个维度下统计结果存在略微不一致；</p>
                        </div>
                        <span class="el-icon-question"></span>
                    </el-tooltip>
                </span>
                <span v-else-if="item.name=='CVR'">
                    <span>{{item.name}}</span>
                    <el-tooltip placement="top">
                        <div slot="content" style="line-height:20px;min-width:150px;max-width:300px">
                            <p>每一次点击带来订单的概率。</p><br>
                            <p>计算规则：CVR = 订单数 / 点击量 * 100%；</p>
                            <p>例如：CVR为10%，每100次点击，能带来10笔广告订单。</p><br>
                            <p>亚马逊广告，CVR一般为5-10%左右，</p>
                            <p>CVR的重要影响因素：商品图片、卖点、评论内容、促销活动，如果CVR低，建议您优化listing，根据商品的受众人群，提炼卖点、设计精美的场景图、善用Q&A，Review等。</p>
                        </div>
                        <span class="el-icon-question"></span>
                    </el-tooltip>
                </span>
                <span v-else-if="item.name=='CPA'">
                    <span>{{item.name}}</span>
                    <el-tooltip placement="top">
                        <div slot="content" style="line-height:20px;min-width:150px;max-width:300px">
                            <p>每一笔广告订单平均所需花费用</p><br>
                            <p>计算规则：CPA = 花费 / 订单数；</p><br>
                            <p>商品的新品期，CPA相对较高，</p>
                            <p>商品的稳定期，CPA逐渐降低。</p>
                        </div>
                        <span class="el-icon-question"></span>
                    </el-tooltip>
                </span>
                <span v-else-if="item.name=='销售额'">
                    <span>{{item.name}}</span>
                    <el-tooltip placement="top">
                        <div slot="content" style="line-height:20px;min-width:150px;max-width:300px">
                            <p>通过广告带来的销售额。</p><br>
                            <p>1、因近30天（尤其近3天）亚马逊接口返回的数据，可能与亚马逊控制台展现的数据存在略微不一致，因此可能导致系统统计结果与亚马逊控制台展现的数据存在略微不一致；</p>
                            <p>2、以商品和站点不同维度查看数据，因数据来自亚马逊不同的接口，不同接口的请求时间或者报告生成时间可能不一致，因此可能导致两个维度下统计结果存在略微不一致；</p>
                        </div>
                        <span class="el-icon-question"></span>
                    </el-tooltip>
                </span>
                <span v-else-if="item.name=='ACoS'">
                    <span>{{item.name}}</span>
                    <el-tooltip placement="top">
                        <div slot="content" style="line-height:20px;min-width:150px;max-width:300px">
                            <p>广告销售成本率，广告花费占广告销售额的比例。</p><br>
                            <p>计算规则：ACoS = 广告花费 / 广告销售额 * 100%；</p>
                            <p>例如，ACoS为90%，100美元的销售额，需要支付90%（90元）广告费。</p>
                            <p>ACoS是衡量广告效果的重要指标，一般来说越低越好，</p>
                            <p>25%是业内普遍的衡量标准，</p>
                            <p>但并不能用ACoS指标单一来衡量广告的效果。</p><br>
                            <p>在不同的细分市场、商品周期、用户周期，品牌定位，都影响着ACoS的设置目标，</p>
                            <p>例如，客户复购率高、忠诚度高、终身价值高，或是有品牌光环和生态的商品，</p>
                            <p>通过广告一次获客，客户可带来长期的购买回报，</p>
                            <p>因此这类商品ACoS高，反而有益于创造更多利润。</p><br>
                            <p>拓展阅读<a href="https://www.baidu.com/s?ie=UTF-8&wd=ACoS%E8%B6%8A%E4%BD%8E%E8%B6%8A%E5%A5%BD%E5%90%97%EF%BC%9F&tn=62095104_41_oem_dg" style="color:#13CE66">ACoS越低越好吗？</a></p>
                        </div>
                        <span class="el-icon-question"></span>
                    </el-tooltip>
                </span>
                <span v-else-if="item.name=='ROAS'">
                    <span>{{item.name}}</span>
                    <el-tooltip placement="top">
                        <div slot="content" style="line-height:20px;min-width:150px;max-width:300px">
                            <p>广告投入回报比，广告销售额与成本的倍数。</p><br>
                            <p>计算规则：RoAS = 销售额 / 花费；</p>
                            <p>例如，RoAS为2，说明花10美元做广告，带来了20美元的销售额。</p><br>
                            <p>RoAS是衡量广告效果投入成本的效率指标，一般情况下，越大越好，亚马逊上的平均RoAS约为3，消费类电子产品的RoAS约为9，玩具和游戏的RoAS约为4.5，</p>
                        </div>
                        <span class="el-icon-question"></span>
                    </el-tooltip>
                </span>
                <span v-else>{{item.name}}</span>
            </template>
            <template slot-scope="scope">
                <span v-if="item.name=='建议竞价'">
                    <el-link v-if="scope.row.suggested==undefinded && scope.row.state!='archived' && scope.row.targetTypeName=='自动'" type='primary' @click="gainBidding(scope.$index,scope.row.adGroupId)">获取</el-link>
                    <div v-if="scope.row.suggested!=undefinded" style="font-size:14px;text-align:left">
                        <p>{{currency}}{{scope.row.suggested}}</p>
                        <p>{{currency}}{{scope.row.rangeStart}} - {{currency}}{{scope.row.rangeEnd}}</p>
                    </div>
                </span>
                <span v-else-if="item.name=='活动状态'">
                    <span v-if="scope.row.campaignState=='已启用'" style="color:#13CE66">{{scope.row.campaignState}}</span>
                    <span v-if="scope.row.campaignState=='已暂停'" style="color:red">{{scope.row.campaignState}}</span>
                    <span v-if="scope.row.campaignState=='已归档'" style="color:#999999">{{scope.row.campaignState}}</span>
                </span>
                <el-link @click="changeGroup(scope.row)" v-else-if="item.name=='广告组合'" style="display:inline-block;border-bottom:1px dashed #999999">{{scope.row.portfolioName}}</el-link>
                <el-link style="display:inline-block;border-bottom:1px dashed #999999" @click="changeDailyBudget(scope.row,scope.$index)" v-else-if="item.name=='竞价'">{{currency}}{{scope.row.defaultBid}}</el-link>
                <span v-else-if="item.name=='点击率'">{{scope.row.clickRate}}<span v-if="scope.row.clickRate!='-'"></span></span>
                <p v-else>
                    <span v-if="item.currency&&scope.row[item.prop]!='-'">{{currency}}</span>
                    {{scope.row[item.prop]}}
                </p>
            </template>
        </el-table-column>
    </el-table>
    <div style="text-align:center;margin:10px">
       <el-pagination
       @size-change="handleSizeChange"
       @current-change="handleCurrentChange"
       :current-page="pageInfo.current"
       :page-sizes="[15, 20, 30, 40]"
       :page-size="15"
       layout="total, sizes, prev, pager, next, jumper"
       :total="total">
       </el-pagination>
    </div>

    <el-dialog
        title="新增广告组"
        :visible.sync="addBox"
        width="65%"
        :before-close="addBoxClose">
        <div>
            <el-form label-width="120px" :model="addInfo" :rules="rules" style="padding:0 80px">
                <el-form-item label="广告活动" prop="campaignId">
                    <el-select 
                    clearable
                    filterable
                    v-model="addInfo.campaignId"
                    placeholder="请选择广告活动"
                    style="width:85%">
                        <el-option 
                        :value="item.id"
                        :label="item.value"
                        v-for="item in campaignList"
                        :key="item.id"
                        @click.native='selectType(item)'>
                            <template>
                                <span>({{item.type}}) </span>
                                <span v-if="item.state=='enabled'" style="color:#1AFA29" class="iconfont icon-duihao"></span>
                                <span v-if="item.state=='paused'" style="font-size:14px;color:red" class="iconfont icon-zantingtingzhi"></span>
                                <span v-if="item.state=='archived'" style="font-size:14px;color:#DCDFE6" class="iconfont icon-guidang"></span>
                                <span> {{item.value}}</span>
                            </template>
                        </el-option>
                    </el-select>
                    <span style="color:#999999;margin-left:15px" v-if="addInfo.campaignId!=''" @click="activeInfo()">活动信息（查看）</span>
                </el-form-item>
                <div class="activeInfo" v-if="showActiveInfo">
                    <div><span>广告组合：</span><span>{{detailActive.value}}</span></div>
                    <div><span>活动起止日期：</span><span>{{detailActive.date}}</span></div>
                    <div><span>每日预算：</span><span>{{currency}}{{detailActive.bid}}</span></div>
                    <div><span>定向策略：</span><span>{{detailActive.targetingType}}</span></div>
                    <div><span>活动竞价策略：</span><span>{{detailActive.target}}</span></div><br>
                    <div><span>商品页面：</span><span>{{detailActive.page}}</span></div>
                    <div><span>搜索结果顶部：</span><span>{{detailActive.top}}</span></div>
                </div>
                <el-form-item label="广告组名称" style="margin-top:20px" prop="name">
                    <template slot="label">
                        <span style="margin-right:3px">广告组名称</span>
                        <el-tooltip placement="top">
                            <div slot="content" style="width:250px;line-height:19px">
                                <p>广告组名称仅自己可见。</p>
                                <p>请设置一个您可以轻松识别并在日后参考的名称。</p>
                            </div>
                            <i class="iconfont icon-wenhao"></i>
                        </el-tooltip>
                    </template>
                    <el-input v-model="addInfo.name" style="width:85%"  placeholder="示例：季节性商品"></el-input>
                </el-form-item>
            </el-form>
            <div style="overflow:hidden">
                <span style="display:inline-block;width:170px;text-align:right;margin-right:3px">商品</span>
                <el-tooltip placement="top">
                    <div slot="content" style="width:250px;line-height:19px">
                        <p>添加您想要在此广告活动中推广的商品。</p><br>
                        <p>1、由于系统同步非实时，因此商品状态、库存数据、售价，可能存在一定延迟；</p>
                        <p>2、分组标签为您在S2系统中设置的；</p>
                        <p>3、预计可售天数是由您在S2系统中配置的近30日销售权重和销售情况计算而得。</p>
                    </div>
                    <i class="iconfont icon-wenhao"></i>
                </el-tooltip>
                <el-select v-model="serchGoods.status" style="width:140px;margin:10px 10px 0 10px" placeholder="商品状态">
                    <el-option 
                    :label="item.value" 
                    :value="item.id" 
                    v-for="item in productStatusList"
                    :key='item.id'></el-option>
                </el-select>
                <!-- <el-select style="width:140px;margin:10px 10px 0 5px" placeholder="品牌">
                    <el-option label="区域一" value="shanghai"></el-option>
                    <el-option label="区域二" value="beijing"></el-option>
                </el-select>
                <el-select style="width:140px;margin:10px 10px 0 5px" placeholder="分组标签">
                    <el-option label="区域一" value="shanghai"></el-option>
                    <el-option label="区域二" value="beijing"></el-option>
                </el-select> -->
                <el-input v-model="searchByAsin" style="width:300px;margin-right:10px;margin-top:4px" placeholder="请输入ASIN"  class="input-with-select">
                    <el-select clearable @clear="clearType" style="width:130px" v-model="selectAsinType" slot="prepend" placeholder="选择输入类型">
                        <el-option 
                        :label="item.value"
                        :value="item.id"
                        v-for="item in searchList"
                        :key="item.id"></el-option>
                    </el-select>
                </el-input>
                <el-button type="primary" size="mini" @click="searchGoods">立即查询</el-button>
                <div class="Box" v-loading='dialogLoading'>
                    <div>
                        <el-tabs v-model="activeName">
                            <el-tab-pane label="搜索" name="first"></el-tab-pane>
                            <el-tab-pane label="输入列表" name="second">
                                <span slot="label" v-if="activeName=='second'">
                                    <span style="margin-right:10px">输入列表</span>
                                    <el-radio v-model="inputList" label="asin">子ASIN</el-radio>
                                    <el-radio v-model="inputList" label="parent_asin">父ASIN</el-radio>
                                </span>
                            </el-tab-pane>
                        </el-tabs>
                        <el-button type="text" class='all' @click="GoodsAll('add')">添加全部</el-button>
                        <div v-if="activeName=='first'">
                            <div style="height:450px;overflow:auto">
                                <div class="item" v-for="item in goodsList" :key="item.id">
                                    <div class="pic">
                                        <img :src="item.imageUrl" alt="" width="100%" >
                                    </div>
                                    <div class="SKU">
                                        <p>
                                            <span class="state"><img :src="item.marketPlaceImageUrl" alt="" width="100%"></span><span>子：{{item.asin}}</span>
                                        </p>
                                        <p>
                                            <span>SKU：{{item.commoditySku}}</span>
                                        </p>
                                    </div>
                                    <div class="realizable">
                                        <p>可售库存：{{item.total}}</p>
                                        <p>可售天数：{{item.canSellDay}}</p>
                                    </div>
                                    <div class="price">
                                        <p>售价：{{currency}}{{item.sellPrice}}</p>
                                    </div>
                                    <div style="font-weight:blod;font-size:23px;color:#409EFF;width:30px"><span @click="toAdded(item)" v-if="item.operation==0" class="el-icon-circle-plus-outline"></span></div>
                                </div>
                            </div>
                            <div style="text-align:right;padding-top:20px">
                                <el-pagination
                                background
                                :current-page.sync="serchGoods.current"
                                @current-change='changeCurrent'
                                layout="prev, pager, next"
                                :total="goodsTotal">
                                </el-pagination>
                            </div>
                        </div>
                        <div v-if="activeName=='second'">
                            <el-input 
                            v-model="manualASIN"
                            type="textarea" rows="14" 
                            placeholder="手动输入ASIN,多个时换行输入 如 
 B077ZKF9ZN 
 B07X1QPRYQ 
 B07V2CLJLV" 
                            style="margin-top:15px"></el-input>
                            <el-button style="float:right;margin-top:10px" @click="manualAdd">添加</el-button>
                        </div>
                    </div>
                    <div>
                        <el-tabs v-model="haveBeenaAdded">
                            <el-tab-pane :label="'已添加'+'('+addedList.length+')'" name="first"></el-tab-pane>
                        </el-tabs>
                        <el-button type="text" class='all' @click="GoodsAll('del')">删除全部</el-button>
                        <div style="height:450px;overflow:auto">
                            <template  v-for="(item,index) in addedList" >
                            <div class="item" :key="item.id">
                                <div class="pic">
                                    <img :src="item.imageUrl" alt="" width="100%">
                                </div>
                                <div class="SKU">
                                    <p>
                                        <span class="state"><img :src="item.marketPlaceImageUrl" alt="" width="100%"></span><span>子：{{item.asin}}</span>
                                    </p>
                                    <p>
                                        <span>SKU：{{item.commoditySku}}</span>
                                    </p>
                                </div>
                                <div class="realizable">
                                    <p>可售库存：{{item.total}}</p>
                                    <p>可售天数：{{item.canSellDay}}</p>
                                </div>
                                <div class="price">
                                    <p>售价：{{currency}}{{item.sellPrice}}</p>
                                </div>
                                <div style="font-weight:blod;font-size:23px;color:#409EFF;width:30px"><span  @click="toGoods(item,index)" class="el-icon-delete"></span></div>
                            </div>
                            </template>
                        </div>
                    </div>
                </div>
                <el-form ref='ggg' label-width="130px" :rules="rules" style="margin:0 40px">
                    <el-form-item label="默认竞价" prop="defaultBid">
                        <el-tooltip placement="top">
                            <div slot="content" style="line-height:19px;width:250px">
                                默认竞价适用于所有点击，除非您为关键词设置不同的竞价。<br>
                                您的竞价可能会发生变化，具体取决于您选择的竞价策略和广告位竞价涨幅。
                            </div>
                            <span class="iconfont icon-wenhao" style="margin: 0 5px 0 -10px"></span>
                        </el-tooltip>
                        <el-input 
                        v-model.number="addInfo.defaultBid" 
                        style="width:150px" 
                        placeholder="">
                        <div style="width:0px" slot="prepend">{{currency}}</div>
                        </el-input>
                    </el-form-item>
                    <el-form-item v-if="type=='自'" label="按定向组设置竞价">
                        <el-tooltip placement="top">
                            <div slot="content" style="line-height:19px;width:350px">
                                <p>定向组使用多种策略来将您的广告与寻找您商品的顾客相匹配，您可以为与广告中的商品匹配的搜索词和商品组选择不同的竞价。</p><br>
                                <p>1、紧密匹配：顾客使用与您的商品紧密相关的搜索词进行搜索时，亚马逊会向其展示您的广告。如果您的商品是“Doppler 400-count Cotton Sheets”，我们将在顾客使用“cotton sheets”和“400-count sheets”等搜索词时展示广告。</p>
                                <p>2、宽泛匹配：顾客使用与您的商品并不密切相关的搜索词进行搜索时，亚马逊会向其展示您的广告。如果您的商品是“Doppler 400-count Cotton Sheets”，我们将在顾客使用“bed sheets”、“bath sheets”和“bath towels”等搜索词时展示广告。</p>
                                <p>3、同类商品：买家查看与您的商品类似的商品的详情页面时，亚马逊会向其展示您的广告。如果您的商品是“Doppler 400-count Cotton Sheets”，我们将在包含“300-count Cotton Sheets”和“Queen 400-count Sheets”的商品详情页上展示广告。</p>
                                <p>4、关联商品：顾客查看与您的商品互补的商品的详情页时，亚马逊会向其展示您的广告。如果商品是“Doppler 400-count Cotton sheets”，我们将在包含“queen quilt”和“feather pillows”的商品详情页上展示广告。</p>
                            </div>
                            <span class="iconfont icon-wenhao" style="margin: 0 5px 0 -10px"></span>
                        </el-tooltip> 
                        <el-switch v-model="AutoTarget" :active-value="true" :inactive-value="false" active-color="#13ce66" inactive-color="#AAAAAA"></el-switch><br>
                        <div v-if="AutoTarget">
                            <div
                            v-for="(item,index) in groupAutoTargetType"
                            :key="item.type">
                                <el-switch style="margin-left:12px" v-model="item.state" active-value='enabled' inactive-value='paused' active-color="#13ce66" inactive-color="#AAAAAA"></el-switch>
                                <span> {{item.value}} </span>  
                                <el-input @input="changeBid(item,index)" v-model="item.bid" style="width:150px" placeholder=""></el-input>
                                <span> 不低于 2，不高于100000。</span>
                            </div>
                        </div>
                    </el-form-item>
                    <el-form-item v-if="type=='手'" label="定向测略">
                        <el-tooltip placement="top">
                            <div slot="content" style="line-height:19px;width:250px">
                                <p>定向使用关键词和商品来帮助您的广告在搜索结果和商品详情页中展示。</p>
                            </div>
                            <span class="iconfont icon-wenhao" style="margin: 0 5px 0 -10px"></span>
                        </el-tooltip>
                        <el-radio v-model="addInfo.type" label="keyword">关键词投放</el-radio><br>
                        <p style="color:#999999;margin-left:10px"> 选择有助于您的商品针对顾客搜索展示的关键词。了解更多信息</p>
                        <el-radio v-model="addInfo.type" style="margin-left:10px" label="target">商品投放</el-radio><br>
                        <p style="color:#999999;margin-left:10px">选择特定商品、品类、品牌或其他商品特征来定向您的广告。了解更多信息</p>
                    </el-form-item>
                    
                </el-form>

                <div class="words" v-if="type=='自'">
                    <el-collapse accordion style="margin-bottom:18px">
                        <el-collapse-item>
                            <template slot="title">
                            <span style="margin-left:20px">否定关键词（可选）</span>
                            <el-tooltip placement="top">
                                <div slot="content" style="width:250px;line-height:19px">
                                    <p>当顾客的搜索词与您的否定关键词匹配时，否定关键词会阻止您的广告展示。</p>
                                    <p>您可以排除不相关的搜索，从而降低广告成本。</p>
                                </div>
                                <i class="iconfont icon-wenhao"></i>
                            </el-tooltip>
                            </template>
                            <div class="keyword" style="border-top: 1px solid #F0F0F0;padding:0 10px">
                                <div>
                                    <span>匹配类型</span>
                                        <el-tooltip placement="top">
                                        <div slot="content" style="line-height:19px;width:250px">
                                            <p>匹配类型让您能够微调哪些顾客搜索内容会触发您的广告。</p><br>
                                            <p>广泛匹配： 包含以任何顺序排列的所有关键词，包括复数、变体和相关关键词。</p>
                                            <p>词组匹配： 包含确切的词组或词序相同的关键词。</p>
                                            <p>精准匹配： 与相应关键词或关键词的词序完全匹配。</p>
                                        </div>
                                        <span class="iconfont icon-wenhao" style="margin-right:9px"></span>
                                    </el-tooltip>
                                    <el-radio v-model="matchType" label="negativeExact">否定精准</el-radio>
                                    <el-radio v-model="matchType" label="negativePhrase">否定词组</el-radio><br>
                                    <el-input v-model="noKeywords" rows="8" type="textarea" placeholder="输入您的列表并用新行将每项隔开"></el-input>
                                    <el-button style="float:right;margin:10px 0px 0 15px" @click="addNoKey">添加关键词</el-button>
                                </div>
                                <div>
                                    <span>已添加</span>
                                    <el-button type="text" style="float:right" @click="addInfo.neKeywordAddReqList=[]">删除全部</el-button>
                                    <el-table
                                        :data="addInfo.neKeywordAddReqList"
                                        height="300"
                                        style="width: 100%;"
                                        cell-style="font-size:14px"
                                        header-cell-style="background:#FAFAFA;font-size:14px">
                                        <el-table-column
                                            prop="keywordText"
                                            label="关键词"
                                            width="width">
                                        </el-table-column>
                                        <el-table-column
                                            prop="matchType"
                                            label="匹配类型"
                                            width="90">
                                            <template slot-scope="scope">
                                                <span v-if="scope.row.matchType=='negativeExact'">否定精准</span>
                                                <span v-else>否定词组</span>
                                            </template>
                                        </el-table-column>
                                        <el-table-column
                                            prop="prop"
                                            header-align="center"
                                            label="删除"
                                            width="50">
                                            <template slot-scope="scope">
                                                <el-button @click="delKeywords(scope.$index, addInfo.neKeywordAddReqList)" style="font-size:23px;" type="text" class="el-icon-delete"></el-button>
                                            </template>
                                        </el-table-column>
                                    </el-table>
                                </div>
                            </div>
                        </el-collapse-item>
                    </el-collapse>
                   </div> 
                   <div class="words" v-if="type=='自'"> 
                    <el-collapse accordion>
                        <el-collapse-item>
                            <template slot="title">
                            <span style="margin-left:20px">否定商品（可选）</span>
                            <el-tooltip placement="top">
                                <div slot="content" style="width:250px;line-height:19px">
                                    <p>否定商品投放会防止您的广告在顾客的搜索内容与您的否定商品选择匹配时展示。</p><br>
                                    <p>这有助于排除不相关的搜索，从而减少广告费用。</p>
                                </div>
                                <i class="iconfont icon-wenhao"></i>
                            </el-tooltip>
                            </template>
                            <div class="keyword" style="border-top: 1px solid #F0F0F0;padding:0 10px">
                                <div>
                                    <span style="margin-bottom:10px">排除全部</span>
                                    <el-tooltip placement="top">
                                        <div slot="content" style="width:250px;line-height:19px">
                                            <p>这是一项高级策略，可防止您的广告展示在特定搜索结果和商品详情页中。</p>
                                        </div>
                                        <span class="iconfont icon-wenhao" style="margin-right:9px"></span>
                                    </el-tooltip>
                                    <el-input rows="8" type="textarea" placeholder="手动输入ASIN，多个时换行输入" v-model="noGoods"></el-input>
                                    <el-button style="float:right;margin:10px 0px 0 15px" @click="addNoGoods">添加</el-button>
                                </div>
                                <div>
                                    <span>已添加({{addInfo.neTargetAddReqList.length}})</span>
                                    <el-button type="text" style="float:right" @click="addInfo.neTargetAddReqList=[]">删除全部</el-button>
                                    <el-table
                                        :data="addInfo.neTargetAddReqList"
                                        style="width: 100%"
                                        header-cell-style="background:#FAFAFA;font-size:14px">
                                        <el-table-column
                                            prop="prop"
                                            label="品牌/商品"
                                            width="width">
                                            <template slot-scope="scope">
                                                <p style="color:#999999;font-size:14px">ASIN:{{scope.row.value}}</p>
                                            </template>
                                        </el-table-column>
                                        <el-table-column
                                            prop="prop"
                                            header-align="center"
                                            label="删除"
                                            width="50">
                                            <template slot-scope="scope">
                                                <el-button @click="delKeywords(scope.$index, addInfo.neTargetAddReqList)" style="font-size:23px;" type="text" class="el-icon-delete"></el-button>
                                            </template>
                                        </el-table-column>
                                    </el-table>
                                </div>
                            </div>
                        </el-collapse-item>
                    </el-collapse>
                </div>
            </div>
            <div class="Box" v-loading='suggestedLoading'  v-if="addInfo.type=='keyword'&&type=='手'">
                    <div>
                        <el-tabs v-model="activeWord" @tab-click="handleClick">
                            <el-tab-pane label="建议" name="first">
                                <span slot="label">建议
                                    <el-tooltip placement="top">
                                        <div slot="content" style="width:250px;line-height:19px">
                                            <p>建议关键词以您广告中的商品为基础。</p>
                                            <p>您可以添加所需的关键词，并在添加后编辑关键词和竞价。 <a style="color:#13CE66" target="top" href="https://advertising.amazon.com/help?entityId=ENTITY3VAZBWP88M62S#GK3MNACNTXG659J9">了解更多信息</a></p>
                                        </div>
                                        <i class="iconfont icon-wenhao"></i>
                                    </el-tooltip>
                                </span>
                            </el-tab-pane>
                            <el-tab-pane label="输入列表" name="second"></el-tab-pane>
                        </el-tabs>
                        <p style="margin-top:15px">
                            <span style="color:#999999;margin-right:4px">筛选条件</span>
                            <el-tooltip placement="top">
                                <div slot="content" style="width:250px;line-height:19px">
                                    <p>匹配类型让您能够微调哪些顾客搜索内容会触发您的广告。</p><br>
                                    <p>广泛匹配： 包含以任何顺序排列的所有关键词，包括复数、变体和相关关键词。</p>
                                    <p>词组匹配： 包含确切的词组或词序相同的关键词。</p>
                                    <p>精准匹配： 与相应关键词或关键词的词序完全匹配。</p>
                                </div>
                                <i class="iconfont icon-wenhao"></i>
                            </el-tooltip>
                            <el-checkbox style="margin-left:4px" v-model="screen" label="exact">精准</el-checkbox>
                            <el-checkbox v-model="screen" label="phrase">词组</el-checkbox>
                            <el-checkbox v-model="screen" label="broad">宽泛</el-checkbox>
                        </p>
                        <el-button type="text" class='all' @click="allKeywordlaunch">添加全部</el-button>
                        <div v-if="activeWord=='first'">
                            <div style="height:400px;overflow:auto">
                                <el-table
                                    v-loading='dialogLoading'
                                    height='390'
                                    :data="keywordList"
                                    style="width: 100%;margin-top:10px;"
                                    header-cell-style="background:#FAFAFA;font-size:14px">
                                    <el-table-column
                                        header-align="left"
                                        prop="prop"
                                        label="关键词"
                                        width="width">
                                        <template slot-scope="scope">
                                            <p style="font-size:14px">{{scope.row}}</p>
                                        </template>
                                    </el-table-column>
                                    <el-table-column
                                        prop="prop"
                                        align="center"
                                        label="添加"
                                        width="70">
                                        <template slot-scope="scope">
                                            <span @click="addKeywords(scope.row)" style="font-size:23px;color:#409EFF;" class="el-icon-circle-plus-outline"></span>
                                        </template>
                                    </el-table-column>
                                </el-table>
                            </div>
                        </div>
                        <div v-if="activeWord=='second'">
                            <el-input 
                            v-model="addKewordInput"
                            type="textarea" rows="14"
                            style="margin-top:15px"></el-input>
                            <el-button style="float:right;margin-top:10px" @click="addInputKeywords">添加</el-button>
                        </div>
                    </div>
                    <div>
                        <el-tabs v-model="activeName" @tab-click="handleClick">
                            <el-tab-pane :label="'已添加'+'('+addInfo.keywordAddReqList.length+')'" name="first"></el-tab-pane>
                        </el-tabs>
                        <el-button type="text" class='all' @click="addInfo.keywordAddReqList=[]">删除全部</el-button>
                        <div style="height:450px;overflow:auto">
                            <el-table
                                :data="addInfo.keywordAddReqList"
                                height='417'
                                header-cell-style="background:#FAFAFA;font-size:14px"
                                style="width: 100%;margin-top:15px"
                                cell-style="font-size:14px">
                                <el-table-column
                                    prop="keywordText"
                                    label="关键词"
                                    width="width">
                                </el-table-column>
                                <el-table-column
                                    header-align="center"
                                    align="center"
                                    prop="matchType"
                                    label="匹配类型"
                                    width="100">
                                    <template slot-scope="scope">
                                        <span v-if="scope.row.matchType=='exact'">精确</span>
                                        <span v-if="scope.row.matchType=='phrase'">词组</span>
                                        <span v-if="scope.row.matchType=='broad'">宽泛</span>
                                    </template>
                                </el-table-column>
                                <el-table-column
                                    header-align="center"
                                    align="center"
                                    prop="prop"
                                    label="竞价"
                                    width="150">
                                    <template slot-scope="scope">
                                        <el-input style="width:120px" v-model="scope.row.bid" placeholder="竞价">
                                            <span slot="append">{{currency}}</span>
                                        </el-input>
                                    </template>
                                </el-table-column>
                                <el-table-column
                                    header-align="center"
                                    align="center"
                                    prop="prop"
                                    label="删除"
                                    width="50">
                                    <template slot-scope="scope">
                                        <el-button @click="delKeywords(scope.$index, addInfo.keywordAddReqList)" style="font-size:23px;" type="text" class="el-icon-delete"></el-button>
                                    </template>
                                </el-table-column>
                            </el-table>
                        </div>
                    </div>
            </div>
            <div class="words"  v-if="addInfo.type=='keyword'&&type=='手'">
                <el-collapse accordion style="margin-bottom:18px">
                    <el-collapse-item>
                        <template slot="title">
                        <span style="margin-left:20px">否定关键词（可选）</span>
                        <el-tooltip placement="top">
                            <div slot="content" style="width:250px;line-height:19px">
                                <p>当顾客的搜索词与您的否定关键词匹配时，否定关键词会阻止您的广告展示。</p>
                                <p>您可以排除不相关的搜索，从而降低广告成本。</p>
                            </div>
                            <i class="iconfont icon-wenhao"></i>
                        </el-tooltip>
                        </template>
                        <div class="keyword" style="border-top: 1px solid #F0F0F0;padding:0 10px">
                            <div>
                                <span>匹配类型</span>
                                <el-tooltip placement="top">
                                    <div slot="content" style="line-height:19px;width:250px">
                                        <p>匹配类型让您能够微调哪些顾客搜索内容会触发您的广告。</p><br>
                                        <p>广泛匹配： 包含以任何顺序排列的所有关键词，包括复数、变体和相关关键词。</p>
                                        <p>词组匹配： 包含确切的词组或词序相同的关键词。</p>
                                        <p>精准匹配： 与相应关键词或关键词的词序完全匹配。</p>
                                    </div>
                                    <span class="iconfont icon-wenhao" style="margin-right:9px"></span>
                                </el-tooltip>
                                <el-radio v-model="matchType" label="negativeExact">否定精准</el-radio>
                                <el-radio v-model="matchType" label="negativePhrase">否定词组</el-radio><br>
                                <el-input v-model="noKeywords" rows="8" type="textarea" placeholder="输入您的列表并用新行将每项隔开"></el-input>
                                <el-button style="float:right;margin:10px 0px 0 15px" @click="addNoKey">添加关键词</el-button>
                            </div>
                            <div>
                                <span>已添加</span>
                                <el-button type="text" style="float:right" @click="addInfo.neKeywordAddReqList=[]">删除全部</el-button>
                                <el-table
                                    :data="addInfo.neKeywordAddReqList"
                                    height="300"
                                    style="width: 100%;"
                                    cell-style="font-size:14px"
                                    header-cell-style="background:#FAFAFA;font-size:14px">
                                    <el-table-column
                                        prop="keywordText"
                                        label="关键词"
                                        width="width">
                                    </el-table-column>
                                    <el-table-column
                                        prop="matchType"
                                        label="匹配类型"
                                        width="90">
                                        <template slot-scope="scope">
                                            <span v-if="scope.row.matchType=='negativeExact'">否定精准</span>
                                            <span v-else>否定词组</span>
                                        </template>
                                    </el-table-column>
                                    <el-table-column
                                        prop="prop"
                                        header-align="center"
                                        label="删除"
                                        width="50">
                                        <template slot-scope="scope">
                                            <el-button @click="delKeywords(scope.$index, addInfo.neKeywordAddReqList)" style="font-size:23px;" type="text" class="el-icon-delete"></el-button>
                                        </template>
                                    </el-table-column>
                                </el-table>
                            </div>
                        </div>
                    </el-collapse-item>
                </el-collapse>
            </div> 
            <div class="goodsPut" v-if="addInfo.type=='target'&&type=='手'">
                    <el-tabs v-model="putType" style="height:30px;" type="card" @tab-click="handleClick">
                        <el-tab-pane label="分类" name="asinCategorySameAs">
                            <span slot="label">分类
                                <el-tooltip placement="top">
                                    <div slot="content" style="width:250px;line-height:19px">
                                        <p>各类商品是按相似性划分在一起的商品，如女士跑鞋。</p>
                                    </div>
                                    <i class="iconfont icon-wenhao"></i>
                                </el-tooltip>
                            </span>
                        </el-tab-pane>
                        <el-tab-pane label="各个商品" name="asinSameAs">
                            <span slot="label">各个商品
                                <el-tooltip placement="top">
                                    <div slot="content" style="width:250px;line-height:19px">
                                        <p>定位到与您广告中宣传的商品类似的各个商品。</p>
                                    </div>
                                    <i class="iconfont icon-wenhao"></i>
                                </el-tooltip>
                            </span>
                        </el-tab-pane>
                    </el-tabs>
                    <el-tabs v-model="putTypeInside" style="height:30px;margin:10px">
                        <el-tab-pane v-if="putType=='asinCategorySameAs'" label="建议" name="first"></el-tab-pane>
                        <el-tab-pane v-if="putType=='asinSameAs'" label="已建议" name="second"></el-tab-pane>
                        <el-tab-pane v-if="putType=='asinSameAs'" label="手动输入" name="third"></el-tab-pane>
                    </el-tabs>
                    <!-- <el-tabs style="height:30px;margin:15px" @tab-click="handleClick">
                        <el-tab-pane label="建议" name="first"></el-tab-pane>
                    </el-tabs> -->
                    <el-table
                        v-loading='suggestLoading'
                        v-if="addInfo.type=='target'&&putTypeInside=='first'"
                        :show-header="false"
                        :data="categoriesList"
                        style="width: 100%">
                        <el-table-column
                            prop="prop"
                            label="label"
                            width="width">
                            <template slot-scope="scope">
                                <p style="color:#999999;font-size:15px">{{scope.row.path}}</p>
                                <p style="font-size:15px">分类：{{scope.row.name}}</p>
                            </template>
                        </el-table-column>
                        <el-table-column
                            prop="prop"
                            label="label"
                            width="33">
                            <template slot-scope="scope">
                                <div style="display:flex;align-items:center;justify-content:space-between">
                                    <span v-show="scope.row.operation==0" @click="addLocationGoods(scope.row)" style="font-size:23px;color:#409EFF;" class="el-icon-circle-plus-outline"></span>
                                </div>
                            </template>
                        </el-table-column>
                        <el-table-column
                            header-align="center"
                            align="center"
                            prop="prop"
                            label="label" 
                            width='60px'>    
                            <template slot-scope="scope"><span style="color:#999999;line-height:1" @click="getGroupDetailInit(scope.row.id,scope.row)">细化</span></template>
                        </el-table-column>
                    </el-table>
                    <el-table
                        v-loading='suggestedLoading'
                        height="400px"
                        v-if="putTypeInside=='second'"
                        :data="targetProductList"
                        style="width: 100%"
                        header-cell-style="background:#DFEFEFE;font-size:14px"
                        >
                        <el-table-column
                            prop="recommendedTargetAsin"
                            label="ASIN"
                            width="width">
                        </el-table-column>
                        <el-table-column
                            align="center"
                            prop="prop"
                            label="添加"
                            width="70">
                            <template slot-scope="scope">
                                <span @click="addLocationGoods(scope.row)" v-if="scope.row.operation==0" style="font-size:23px;color:#409EFF;" class="el-icon-circle-plus-outline"></span>
                            </template>
                        </el-table-column>
                    </el-table>
                    <div v-if="putTypeInside=='third'">
                        <el-input 
                        v-model="inputASIN"
                        style="margin-top:10px;"
                        rows="10"
                        cols='43'
                        type="textarea"
                        placeholder="手动输入ASIN,多个时换行输入 如 
B077ZKF9ZN 
B07X1QPRYQ 
B07V2CLJLV" >
                        </el-input>
                        <el-button style="float:right;margin-top:10px" @click="addLocationGoods">添加</el-button>
                    </div>
                    <p style="line-height:1;text-align:center;margin-top:40px">定位商品数<el-button type="text" style="float:right;margin-bottom:5px" @click="removeAll('all')">全部删除</el-button></p>
                    <el-table
                        :data="addInfo.targetAddReqList"
                        style="width: 100%"
                        header-cell-style="background:#FAFAFA;font-size:14px">
                        <el-table-column
                            prop="prop"
                            label="分类和商品"
                            width="width">
                            <template slot-scope="scope" style="font-size:14px">
                                <div v-if="scope.row.yet!=1">
                                    <p v-if="scope.row.amzAdTargetExpressionList[0].type=='asinCategorySameAs'" style="color:#999999;font-size:15px">{{scope.row.path}}</p>
                                    <p v-if="scope.row.amzAdTargetExpressionList[0].type=='asinCategorySameAs'" style="font-size:15px">
                                        <span>分类：{{scope.row.amzAdTargetExpressionList[0].name}}</span>&ensp;&ensp;
                                        <span v-if="scope.row.name!=undefined">品牌：{{scope.row.name}}</span>&ensp;&ensp;
                                        <span v-if="scope.row.asinPriceGreaterThan!=undefined && scope.row.asinPriceGreaterThan!=''&& scope.row.asinPriceLessThan!=undefined && scope.row.asinPriceLessThan!=''">价格区间：{{scope.row.asinPriceGreaterThan + ' - ' +scope.row.asinPriceLessThan}}</span>&ensp;&ensp;
                                        <span v-if="scope.row.asinPriceLessThan!=''&&scope.row.asinPriceGreaterThan=='' || scope.row.asinPriceGreaterThan!=''&&scope.row.asinPriceLessThan==''">商品价格：
                                            <span v-if="scope.row.asinPriceLessThan!=''">不高于 {{scope.row.asinPriceLessThan}}</span>
                                            <span v-if="asinPriceGreaterThan!=''">不低于 {{scope.row.asinPriceGreaterThan}}</span>
                                        </span>&nbsp;&nbsp;
                                        <span v-if="scope.row.prime!=undefined&&scope.row.prime!=''">配送：{{scope.row.prime}}</span>&nbsp;&nbsp;
                                        <span v-if="scope.row.asinReviewRatingBetween!=undefined&&scope.row.asinReviewRatingBetween.length!=0">评分：{{scope.row.asinReviewRatingBetween[0]+'-'+ scope.row.asinReviewRatingBetween[1]}}</span>
                                    </p>
                                    <p v-if="scope.row.amzAdTargetExpressionList[0].type=='asinSameAs'">{{scope.row.amzAdTargetExpressionList[0].name}}</p>
                                </div>
                            </template>
                        </el-table-column>
                        <el-table-column
                            header-align="center"
                            align="center"
                            prop="prop"
                            label="竞价"
                            width="170">
                            <template slot-scope="scope">
                                <el-input style="width:120px" v-model="scope.row.bid" placeholder="竞价">
                                    <span slot="append">{{currency}}</span>
                                </el-input>
                            </template>
                        </el-table-column>
                        <el-table-column
                            align="center"
                            prop="prop"
                            label="删除"
                            width="70">
                            <template slot-scope="scope">
                                <span @click="remove(scope.$index, addInfo.targetAddReqList)" style="font-size:23px;color:#409EFF;" class="el-icon-delete"></span>
                            </template>
                        </el-table-column>
                    </el-table>
            </div>
            <div class="words" style="margin-top:20px" v-if="addInfo.type=='target'&&type=='手'"> 
                    <el-collapse accordion>
                        <el-collapse-item>
                            <template slot="title">
                            <span style="margin-left:20px">否定商品（可选）</span>
                            <el-tooltip placement="top">
                                <div slot="content" style="width:250px;line-height:19px">
                                    <p>否定商品投放会防止您的广告在顾客的搜索内容与您的否定商品选择匹配时展示。</p><br>
                                    <p>这有助于排除不相关的搜索，从而减少广告费用。</p>
                                </div>
                                <i class="iconfont icon-wenhao"></i>
                            </el-tooltip>
                            </template>
                            <div class="keyword" style="border-top: 1px solid #F0F0F0;padding:0 10px">
                                <div>
                                    <el-tabs v-model="noGoodsType" @tab-click="noGoods=''">
                                        <el-tab-pane label="排除品牌" name="asinBrandSameAs">
                                            <span slot="label">排除品牌
                                                <el-tooltip placement="top">
                                                    <div slot="content" style="width:250px;line-height:19px">
                                                        <p>这是一项高级策略，可防止您的广告展示在特定搜索结果和商品详情页中。</p>
                                                    </div>
                                                    <i class="iconfont icon-wenhao"></i>
                                                </el-tooltip>
                                            </span>
                                        </el-tab-pane>
                                        <el-tab-pane label="排除全部" name="asinSameAs">
                                            <span slot="label">排除全部
                                                <el-tooltip placement="top">
                                                    <div slot="content" style="width:250px;line-height:19px">
                                                        <p>这是一项高级策略，可防止您的广告展示在特定搜索结果和商品详情页中。</p>
                                                    </div>
                                                    <i class="iconfont icon-wenhao"></i>
                                                </el-tooltip>
                                            </span>
                                        </el-tab-pane>
                                    </el-tabs>
                                    <div v-if="noGoodsType=='asinSameAs'">
                                        <el-input v-model="noGoods" style="margin-top:10px" rows="8" type="textarea" placeholder="手动输入ASIN，多个时换行输入"></el-input>
                                        <el-button @click="toTargetsBrandsList" style="float:right;margin:10px 0px 0 15px">添加</el-button>
                                    </div>
                                    <div v-if="noGoodsType=='asinBrandSameAs'">
                                        <el-input placeholder="请输入内容" v-model="targetsBrandsInfo.keyword" class="input-with-select" style="margin-top:10px">
                                            <el-button @click="getTargetsBrandsList" :loading='searchLoading' slot="append" icon="el-icon-search"></el-button>
                                        </el-input>
                                        <div>
                                            <el-table
                                                v-loading='searchLoading'
                                                height="300px"
                                                :show-header="false"
                                                :data="targetsBrandsList"
                                                style="width: 100%;"
                                                cell-style="font-size:14px"
                                                header-cell-style="background:#FAFAFA;font-size:14px">
                                                <el-table-column
                                                    prop="name"
                                                    label="label"
                                                    width="width">
                                                </el-table-column>
                                                <el-table-column
                                                    prop="prop"
                                                    label="label"
                                                    width="50">
                                                    <template slot-scope="scope">
                                                        <span v-if="scope.row.operation==0" @click="toTargetsBrandsList(scope.row,scope.$index)" style="font-size:23px;color:#409EFF;" class="el-icon-circle-plus-outline"></span>
                                                    </template>
                                                </el-table-column>
                                            </el-table>
                                        </div>
                                    </div>
                                </div>
                                <div>
                                    <span>已添加</span>
                                    <el-button @click="DELLnoGoods" type="text" style="float:right">删除全部</el-button>
                                    <el-table
                                        :data="addInfo.neTargetAddReqList"
                                        style="width: 100%"
                                        header-cell-style="background:#DFEFEFE;font-size:14px">
                                        <el-table-column
                                            prop="prop"
                                            label="品牌/商品"
                                            width="width">
                                            <template slot-scope="scope">
                                                <p style="color:#999999;font-size:14px"><span v-if="scope.row.type=='asinBrandSameAs'">品牌：</span><span v-if="scope.row.type=='asinSameAs'">ASIN：</span>{{scope.row.name}}</p>
                                            </template>
                                        </el-table-column>
                                        <el-table-column
                                            prop="prop"
                                            header-align="center"
                                            label="删除"
                                            width="50">
                                            <template slot-scope="scope">
                                                <span v-if="scope.row.yet!=1" style="font-size:23px;color:#409EFF;" class="el-icon-delete" @click="delItem(scope.$index,scope.row.name)"></span>
                                                <span style="color:#999999;font-size:14px" v-if="scope.row.yet==1">已存在</span>
                                            </template>
                                        </el-table-column>
                                    </el-table>
                                </div>
                            </div>
                        </el-collapse-item>
                    </el-collapse>
            </div>
        </div>
        <!-- 细化 -->
        <el-dialog
            width="40%"
            :visible.sync="elaborationBox"
            :before-close="elaborationClose"
            append-to-body>
                <template slot="title">
                    <p>细化产品分类：&nbsp;</p>
                    <p style="color:#999999;font-size:14px;margin-top:10px;border-bottom:1px #D9D9D9 solid;padding-bottom:10px">根据特定品牌、价格范围、星级和Prime配送资格，细化分类</p>
                </template>
                <div style="margin-top:-20px;padding:0 20px">
                    <p>品牌</p>
                    <el-select
                        style="width:100%;margin:10px 0"  placeholder="请输入或选择品牌"
                        v-model="elaborationInfo.asinBrandSameAs"
                        multiple
                        filterable
                        allow-create
                        default-first-option>
                        <el-option
                        v-for="item in brandList"
                        :key="item.id"
                        :label="item.name"
                        :value="item.id">
                        </el-option>
                    </el-select>
                    <p>商品价格范围</p>
                    <el-input v-model="elaborationInfo.asinPriceGreaterThan" placeholder="无最低商品价格" el-input style="width:180px;margin:15px 0 ">
                    <el-button slot="append">{{currency}}</el-button>
                    </el-input> -  <el-input v-model="elaborationInfo.asinPriceLessThan" placeholder="无最高商品价格" style="width:180px;margin:10px 0">
                        <el-button slot="append">{{currency}}</el-button>
                    </el-input>
                    <p>查看星级评定 0 星 ~ 5 星</p>
                    <div class="block" style="margin:15px 0 30px 0">
                        <el-slider
                        v-model="elaborationInfo.asinReviewRatingBetween"
                        range
                        :marks="marks"
                        show-stops
                        :max="5">
                        </el-slider>
                    </div>
                    <p style="margin:20px 0">配送</p>
                    <el-radio 
                    v-model="elaborationInfo.asinIsPrimeShippingEligible" 
                    :label="String(item.id)"
                    v-for="item in primeTypeList"
                    :key="item.id">{{item.value}}</el-radio>
                </div>
                <span slot="footer" class="dialog-footer">
                    <el-button @click="elaborationClose">取 消</el-button>
                    <el-button type="primary" @click="addElaboration">确 定</el-button>
                </span>
        </el-dialog>
        <div slot="footer">
            <el-button @click="addBoxClose">取 消</el-button>
            <el-button type="primary" @click='sure' :loading='buttonLoading'>启动广告组</el-button>
        </div>

    </el-dialog>
    <el-dialog
        title="批量调整竞价"
        :visible.sync="batchBiddingBox"
        width="30%"
        :before-close="dialogBeforeClose">
        <div style="line-height:30px;padding:10px">
            <div style="text-align:center;margin-bottom:20px">
                <el-select v-model="batchInfo.defaultBidUpdateReq.operateType" style="width:200px;" placeholder="">
                    <el-option
                        v-for="item in adjustBidOperateTypeList"
                        :key="item.id"
                        :label="item.values"
                        :value="item.id"
                        @click.native="setSumType(item.values)">
                    </el-option>
                </el-select> -
                <el-input style="width:200px;" v-model="sumInput" :placeholder="sumType=='amount'?'请输入金额':'请输入百分比'">
                    <span v-if="sumType=='amount'" slot="prepend">{{currency}}</span>
                    <span v-if="sumType=='percent'" slot="append">%</span>
                </el-input>
            </div>
            <p style="font-size:14px;color:#999999">说明：</p>
            <p style="font-size:14px;color:#999999">1、将竞价统一调整到：输入的金额 就是 目标竞价；</p>
            <p style="font-size:14px;color:#999999">2、将竞价按百分比提高/降低：目标竞价 = (1 ± 输入值) / 100 * 当前的竞价；</p>
            <p style="font-size:14px;color:#999999">3、将竞价按固定金额增加/减少：目标竞价 = 输入的金额 ± 当前的竞价；</p>
            <p style="font-size:14px;color:#999999">4、调整后的目标竞价，小于2时，系统会将目标竞价调整为2，大于100000时，系统调整为100000；</p>
            <p style="font-size:14px;color:#999999">5、调整后的目标竞价，按四舍五入最多保留两位小数；</p>
        </div>
        <div slot="footer">
            <el-button @click="batchBidding('cancel')">取 消</el-button>
            <el-button type="primary" @click="batchBidding">确 定</el-button>
        </div>
    </el-dialog>
    <el-dialog
        title="更改广告组"
        :visible.sync="updateGroupBox"
        width="65%"
        :before-close="updateGroupBoxClose">
        <div v-loading='buttonLoading'>
            <el-tabs v-model="selectUpdateGroup" style="margin-bottom:20px" @tab-click="getListNegativeTarget">
                <el-tab-pane label="广告组设置" name="setGroup"></el-tab-pane>
                <el-tab-pane label="否定投放" name="noLaunch"></el-tab-pane>
            </el-tabs>
            <el-form 
            v-if="selectUpdateGroup=='setGroup'"
            :rules="{
                name:[{required:true,message:'请输入广告组名称',trigger:'blur'}],
                defaultBid:[{required:true,message:'请输入默认竞价',trigger:'blur'}],

            }"
            ref="updateGroupInfo" 
            :model="updateGroupInfo" 
            label-width="100px"
            style="width:70%;margin:20px auto 0">
                <el-form-item label="广告组名称" prop="name">
                    <el-input v-model="updateGroupInfo.name" placeholder="请输入广告组名称"></el-input>
                </el-form-item>
                <el-form-item label="广告组状态">
                    <el-select v-model="updateGroupInfo.state" placeholder="请选择活动区域" style="width:100%">
                        <el-option label="启动" value="enabled"></el-option>
                        <el-option label="暂停" value="paused"></el-option>
                        <el-option label="归档" value="archived"></el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="默认竞价" prop="defaultBid">
                    <el-input v-model="updateGroupInfo.defaultBid" placeholder="请输入默认竞价">
                        <span slot="prepend">{{currency}}</span>
                    </el-input>
                </el-form-item>
            </el-form>
            <div v-if="selectUpdateGroup=='noLaunch'">
                <div class="words" v-if="targetType=='自动'||targetType=='关键词'">
                <el-collapse accordion style="margin-bottom:18px">
                    <el-collapse-item>
                        <template slot="title">
                        <span style="margin-left:20px">否定关键词</span>
                        <el-tooltip placement="top">
                            <div slot="content" style="width:250px;line-height:19px">
                                <p>当顾客的搜索词与您的否定关键词匹配时，否定关键词会阻止您的广告展示。</p>
                                <p>您可以排除不相关的搜索，从而降低广告成本。</p>
                            </div>
                            <i class="iconfont icon-wenhao"></i>
                        </el-tooltip>
                        </template>
                        <div class="keyword" style="border-top: 1px solid #F0F0F0;padding:0 10px">
                            <div>
                                <span>匹配类型</span>
                                <el-tooltip placement="top">
                                    <div slot="content" style="line-height:19px;width:250px">
                                        <p>匹配类型让您能够微调哪些顾客搜索内容会触发您的广告。</p><br>
                                        <p>广泛匹配： 包含以任何顺序排列的所有关键词，包括复数、变体和相关关键词。</p>
                                        <p>词组匹配： 包含确切的词组或词序相同的关键词。</p>
                                        <p>精准匹配： 与相应关键词或关键词的词序完全匹配。</p>
                                    </div>
                                    <span class="iconfont icon-wenhao" style="margin-right:9px"></span>
                                </el-tooltip>
                                <el-radio v-model="matchType" label="negativeExact">否定精准</el-radio>
                                <el-radio v-model="matchType" label="negativePhrase">否定词组</el-radio><br>
                                <el-input v-model="noKeywords" rows="8" type="textarea" placeholder="输入您的列表并用新行将每项隔开"></el-input>
                                <el-button style="float:right;margin:10px 0px 0 15px" @click="addNoKey">添加关键词</el-button>
                            </div>
                            <div v-loading="dialogLoading">
                                <span style="margin-right:10px;color:#409EFF;cursor: pointer;" v-show="addedNoKeyword!=0" @click="updateNeKeyword">归档</span>
                                <span>已存在{{addedNoKeyword}}个，并添加{{addInfo.neKeywordAddReqList.length - addedNoKeyword}}</span>
                                <el-button type="text" style="float:right" @click="delAllkeyword">删除全部</el-button>
                                <el-table
                                    :data="addInfo.neKeywordAddReqList"
                                    height="300"
                                    style="width: 100%;"
                                    cell-style="font-size:14px"
                                    @selection-change="selectNoKeywords"
                                    header-cell-style="background:#FAFAFA;font-size:14px">
                                    <el-table-column
                                    type="selection"
                                    width="55"
                                    :selectable="archivedKeywordSelect"
                                    ></el-table-column>
                                    <el-table-column
                                        prop="keywordText"
                                        label="关键词"
                                        width="width">
                                    </el-table-column>
                                    <el-table-column
                                        prop="matchType"
                                        label="匹配类型"
                                        width="90">
                                        <template slot-scope="scope">
                                            <span v-if="scope.row.matchType=='negativeExact'">否定精准</span>
                                            <span v-else>否定词组</span>
                                        </template>
                                    </el-table-column>
                                    <el-table-column
                                        prop="prop"
                                        align="center"
                                        header-align="center"
                                        label="删除"
                                        width="90">
                                        <template slot-scope="scope">
                                            <el-button v-if="scope.row.operation!=1" @click="delNegativeKeyword(scope.$index,scope.row.keywordText)" style="font-size:23px;" type="text" class="el-icon-delete"></el-button>
                                            <span v-if="scope.row.operation==1" style="color:#999999;font-size:14px">已存在</span>
                                        </template>
                                    </el-table-column>
                                </el-table>
                            </div>
                        </div>
                        <el-button type='primary' style="float:right;margin:5px" @click="addNegativeKeyword" :loading='buttonLoading'>确定新增否定关键词</el-button>
                    </el-collapse-item>
                </el-collapse>
            </div>
            <div v-if="targetType=='自动'||targetType=='商品'" class="words" style="margin-top:20px"> 
                    <el-collapse accordion>
                        <el-collapse-item>
                            <template slot="title">
                            <span style="margin-left:20px">否定商品</span>
                            <el-tooltip placement="top">
                                <div slot="content" style="width:250px;line-height:19px">
                                    <p>否定商品投放会防止您的广告在顾客的搜索内容与您的否定商品选择匹配时展示。</p><br>
                                    <p>这有助于排除不相关的搜索，从而减少广告费用。</p>
                                </div>
                                <i class="iconfont icon-wenhao"></i>
                            </el-tooltip>
                            </template>
                            <div class="keyword" style="border-top: 1px solid #F0F0F0;padding:0 10px">
                                <div>
                                    <el-tabs v-model="noGoodsType" @tab-click="noGoods=''">
                                        <el-tab-pane v-if="targetType=='商品'" label="排除品牌" name="asinBrandSameAs">
                                            <span slot="label">排除品牌
                                                <el-tooltip placement="top">
                                                    <div slot="content" style="width:250px;line-height:19px">
                                                        <p>这是一项高级策略，可防止您的广告展示在特定搜索结果和商品详情页中。</p>
                                                    </div>
                                                    <i class="iconfont icon-wenhao"></i>
                                                </el-tooltip>
                                            </span>
                                        </el-tab-pane>
                                        <el-tab-pane label="排除全部" name="asinSameAs">
                                            <span slot="label">排除全部
                                                <el-tooltip placement="top">
                                                    <div slot="content" style="width:250px;line-height:19px">
                                                        <p>这是一项高级策略，可防止您的广告展示在特定搜索结果和商品详情页中。</p>
                                                    </div>
                                                    <i class="iconfont icon-wenhao"></i>
                                                </el-tooltip>
                                            </span>
                                        </el-tab-pane>
                                    </el-tabs>
                                    <div v-if="noGoodsType=='asinSameAs'">
                                        <el-input v-model="noGoods" style="margin-top:10px" rows="8" type="textarea" placeholder="手动输入ASIN，多个时换行输入"></el-input>
                                        <el-button @click="toTargetsBrandsList" style="float:right;margin:10px 0px 0 15px">添加</el-button>
                                    </div>
                                    <div v-if="noGoodsType=='asinBrandSameAs' && targetType=='商品'">
                                        <el-input placeholder="请输入内容" v-model="targetsBrandsInfo.keyword" class="input-with-select" style="margin-top:10px">
                                            <el-button @click="getTargetsBrandsList" :loading='searchLoading' slot="append" icon="el-icon-search"></el-button>
                                        </el-input>
                                        <div>
                                            <el-table
                                                v-loading='searchLoading'
                                                height="300px"
                                                :show-header="false"
                                                :data="targetsBrandsList"
                                                style="width: 100%;"
                                                cell-style="font-size:14px"
                                                header-cell-style="background:#FAFAFA;font-size:14px">
                                                <el-table-column
                                                    prop="name"
                                                    label="label"
                                                    width="width">
                                                </el-table-column>
                                                <el-table-column
                                                    prop="prop"
                                                    label="label"
                                                    width="50">
                                                    <template slot-scope="scope">
                                                        <span v-if="scope.row.operation==0" @click="toTargetsBrandsList(scope.row,scope.$index)" style="font-size:23px;color:#409EFF;" class="el-icon-circle-plus-outline"></span>
                                                    </template>
                                                </el-table-column>
                                            </el-table>
                                        </div>
                                    </div>
                                </div>
                                <div v-loading='dialogLoading'>
                                    <span style="margin-right:10px;color:#409EFF;cursor: pointer;" v-show="addedNoGoods!=0"  @click="archivedNeTarget">归档</span>
                                    <span>已存在{{addedNoGoods}}个，并添加{{addInfo.neTargetAddReqList.length - addedNoGoods}}</span>
                                    <el-button type="text" style="float:right" @click="delAllnoGood">删除全部</el-button>
                                    <el-table
                                        @selection-change="selectNoGoods"
                                        :data="addInfo.neTargetAddReqList"
                                        style="width: 100%"
                                        header-cell-style="background:#DFEFEFE;font-size:14px">
                                        <el-table-column
                                        type="selection"
                                        width="50"
                                        :selectable="archivedSelect"
                                        ></el-table-column>
                                        <el-table-column
                                            prop="prop"
                                            label="品牌/商品"
                                            width="width">
                                            <template slot-scope="scope">
                                                <p style="color:#999999;font-size:14px">
                                                    <span v-if="scope.row.type=='asinBrandSameAs'&&scope.row.yet!=1">品牌：{{scope.row.name}}</span>
                                                    <span v-if="scope.row.type=='asinSameAs'&&scope.row.yet!=1">ASIN：{{scope.row.value}}</span>
                                                    <span v-if="scope.row.yet==1">
                                                        <span v-if="scope.row.type=='asinBrandSameAs'">品牌：</span>
                                                        <span v-if="scope.row.type=='asinSameAs'">ASIN：</span>
                                                        {{scope.row.value}}
                                                    </span>
                                                </p>
                                            </template>
                                        </el-table-column>
                                        <el-table-column
                                            prop="prop"
                                            align="center"
                                            header-align="center"
                                            label="删除"
                                            width="70">
                                            <template slot-scope="scope">
                                                <span v-if="scope.row.yet!=1" style="font-size:23px;color:#409EFF;" class="el-icon-delete" @click="delItem(scope.$index,scope.row.name)"></span>
                                                <span style="color:#999999;font-size:14px" v-if="scope.row.yet==1">已存在</span>
                                            </template>
                                        </el-table-column>
                                    </el-table>
                                </div>
                            </div>
                        <el-button type='primary' style="float:right;margin:5px" @click="addNeTargets" :loading='buttonLoading'>确定新增否定商品定位</el-button>
                        </el-collapse-item>
                    </el-collapse>
                </div>
            </div>
        </div>
        <div slot="footer" v-if="selectUpdateGroup=='setGroup'">
            <el-button @click="updateGroupBoxClose">取 消</el-button>
            <el-button :loading='buttonLoading' type="primary" @click="update">确 定</el-button>
        </div>
    </el-dialog>
    <!-- 底部抽屉区域 -->
    <el-drawer
      title="广告组表现"
      :modal="false"
      size="60%"
      :visible.sync="drawer"
      :direction="direction"
      :custom-class="DrawerClass"
      :before-close="handleClose"
    >
    <TendencyChart 
    :list.sync='ListOfData' 
    :info='`广告组:${searchkeywordText}`'
    :legendList='legendList'
    :currency="currency"
    @analysisClick='analysisClick'></TendencyChart>
    </el-drawer>
  </div>
</template>

<script>
import tableDialog from '@/components/tableDialog.vue'
import TendencyChart from '@/views/Advertising/TendencyChart'
import TimeQuantum from '@/components/TimeQuantum.vue'
import {getGoods,
        addInit,
        addGroup,
        getAsinByAsins,
        ListPage,
        pageInit,
        getKeywordsByAsin,
        getCategories,
        getTargetProduct,
        getTargetsBrands,
        batchUpdate,
        changeGroup,
        listNegativeTarget,
        updateInit,
        listNegativeKeywords,
        addNegativeKeyword,
        addNeTargets,
        getGroupSuggestBid,
        focusOn,
        focusOff,
        archivedNeTarget,
        updateNeKeyword,
        groupDetailInit,
        updateBid,
        goodsPageInit,
        listByGroup
        } from '@/api/Advertising/group.js'
export default {
    name:'group',
    components:{
        tableDialog,
        TendencyChart,
        TimeQuantum
    },
    data(){
        return {
            drawer: false,
            direction: 'btt',
            currency:'',
            updateGroupBox:false,
            dialogLoading:false,
            suggestLoading:false,
            loading:false,
            buttonLoading:false,
            addBox:false,
            batchBiddingBox:false,
            searchLoading:false,
            selectUpdateGroup:'setGroup',
            targetType:'',
            addNegativeKeywordsInfo:{//新增否定关键词信息
                adGroupId:'',
                amzAdKeywordForGroupAddReqList:[],
                campaignId:'',
                profileId:'',
            },
            addNeTargetsInfo:{
                adGroupId:'',
                amzAdTargetExpressionList:[],
                campaignId:'',
                expressionType:'manual',
            },
            activeName:'first',
            activeWord:'first',
            inputList:'asin',
            tableData:[],
            manualASIN:'',
            noKeywords:'',//手动自动公用
            noKeyList:[],
            noGoods:'',
            noGoodsType:'asinSameAs',
            noGoodsInput:'',
            noGoodsList:[],
            matchType:'negativeExact',
            asinType:'',
            keywordList:'',
            addKewordInput:'',
            // addedKeywordsList:[],
            screen:['exact','phrase','broad'],//关键字投放筛选
            type:'',//手动或是自动
            putType:'asinCategorySameAs',
            putTypeInside:'first',
            categoriesList:[],
            locationGoodsList:[],
            targetProductList:[],
            headList:[
                {name:'广告活动',prop:'campaignName', width:160},
                {name:'活动状态',prop:'campaignState', width:100},
                {name:'投放类型',prop:'targetTypeName', width:100},
                {name:'定时策略',prop:'', width:100},
                {name:'建议竞价',prop:'', width:130},
                {name:'竞价',prop:'defaultBid', width:100,sortable:true,currency:true},
                {name:'曝光量',prop:'impressions',width:121,sortable:true},
                {name:'点击量',prop:'clicks',width:121,sortable:true},
                {name:'点击率',prop:'clickRate',width:121,sortable:true},
                {name:'花费',prop:'cost',width:101,sortable:true,currency:true},
                {name:'CPC均价',prop:'clickAveCost',width:120,sortable:true,currency:true},
                {name:'订单数',prop:'attributedConversions14d',width:131,sortable:true},
                {name:'CVR',prop:'cvr',width:101,sortable:true},
                {name:'CPA',prop:'cpa',width:101,sortable:true},
                {name:'销售额',prop:'attributedSales14d',width:131,sortable:true,currency:true},
                {name:'ACoS',prop:'acos',width:101,sortable:true},
                {name:'ROAS',prop:'roas',width:101,sortable:true},
            ],
            tableHead:[],
            addInfo:{
                amzAdProductAddReqList:[],
                campaignId:'',
                defaultBid:'',
                keywordAddReqList:[],
                neKeywordAddReqList:[],
                name:'',
                neTargetAddReqList:[],
                targetAddReqList:[],
                targetGroups:[],
                type:'',
                elaborationRow:''
            },
            serchGoods:{
                current:'1',
                status:'',
                queryFrom:'advert' 
            },
            showActiveInfo:false,
            goodsTotal:'',
            goodsList:[],
            addedList:[],
            campaignList:[],
            pageInfo:{
                current:'',
                profileId:null,
                startDate:'',
                endDate:'',
                portfolioId:'',
                campaignId:'',
                groupsState:'',
                campaignStatus:'',
                targetType:'',
                keyword:'',
                pageSize:'15',
                sort:'',
                sortColumn:'',
                isFavorite:'',
            },
            total:'',
            adGroupStateList:[],
            adGroupTargetTypeList:[],
            adjustBidOperateTypeList:[],
            campaignList:[],
            screenedCampaignList:[],
            operateTypeList:[],
            campaignStateList:[],
            portfolioList:[],
            shopId:[],
            favoriteTypeList:[],
            groupAutoTargetType:[],
            targetsBrandsInfo:{
                keyword:'',
                categoryId:'',
            },
            targetsBrandsList:[],
            inputASIN:'',

            // 批量
            batchInfo:{
                defaultBidUpdateReq:{
                    amount:'',
                    operateType:'1',
                    percent:'',
                },
                groupIds:[],
                operateType:''
            },
            productStatusList:[],
            batchList:[],
            sumInput:'',
            sumType:'amount',
            AutoTarget:false,

            rules:{
                // defaultBid:[{required:true,message:'请填写默认竞价',trigger:'blur'}],
                campaignId:[{required:true,message:'请选择广告活动',trigger:'blur'}],
                name:[{required:true,message:'请填写广告组名称',trigger:'blur'}]
            },
            updateGroupInfo:{
                defaultBid:'',
                id:'',
                name:'',
                state:''
            },
            archivednoGoodsIds:[],//归档否定商品
            archivedNoKeywordsIds:[],//归档否定关键字
            detailActive:{
                campaignTargetType:'',
                date:'',
                state:'',
                target:'',
                targetingType:'',
                top:'',
                page:'',
                type:'',
                value:''
            },
            // 细化部分包括addInfo里面的e...
            haveBeenaAdded:'first',
            brandList:[],
            detailTypeList:[],
            primeTypeList:[],
            elaborationBox:false,
            elaborationInfo:{
                asinBrandSameAs:[],
                asinPriceLessThan:'',//最高价
                asinPriceBetween:'',//价格去加
                asinPriceGreaterThan:'',//最低价
                asinReviewRatingBetween:[0,5],//星区间
                asinIsPrimeShippingEligible:''//,派送
            },
            marks:{0:'0星',1:'1星',2:'2星',3:'3星',4:'4星',5:'5星',},
            searchByAsin:'',
            selectAsinType:'',
            ListOfData: [],
            startTime: "",
            endTime: "",
            legendList: [
                {name:"曝光量",prop:'impressions'},
                {name:"点击量",prop:'clicks'},
                {name:"点击率",prop:'clickRate',persent:true},
                {name:"花费",prop:'cost',currency:true},
                {name:"CPC均价",prop:'clickAveCost',currency:true},
                {name:"订单数",prop:'attributedConversions14d'},
                {name:"CVR",prop:'cvr',persent:true},
                {name:"CPA",prop:'cpa',currency:true},
                {name:"销售额",prop:'attributedSales14d',currency:true},
                {name:"ACoS",prop:'acos',persent:true},
                {name:"RoAS",prop:'roas',persent:true},
            ],
            chartInatance: null,  //  保存chartsInstance 数据
            searchkeywordText: "",
            shopID:'',
            marketPlaceID:'',
        };
    },
    created(){
        this.tableHead=this.headList
        this.getPageInit()
    },
    watch:{
        $route(to){
            const info = this.$route.query
            if(info.type=='getOne'){
                this.pageInfo.profileId = Number(info.profileId);
                this.pageInfo.keyword=info.value
                this.getListPage()
            }else{
                this.pageInfo.profileId = Number(info.profileId);
                this.pageInfo.keyword=''
                this.pageInfo.groupsState=info.state
                this.screenedCampaignList.forEach(item=>{
                    if(info.campaignId==item.id){
                        this.pageInfo.campaignId=item.id
                        this.pageInfo.portfolioId=item.profileId
                    }
                })
                this.getListPage()
            }
        }
    },
    computed:{
      addedNoKeyword(){
        let num = 0
        this.addInfo.neKeywordAddReqList.forEach(item=>{
          if(item.operation==1){
            num++
          }
        })
        return num
      },
      addedNoGoods(){
        let num = 0
        this.addInfo.neTargetAddReqList.forEach(item=>{
          if(item.yet==1){
            num++
          }
        })
        return num
      },
    },
    methods:{
        getID(id,currency,shopId,marId){
            this.pageInfo={
                current:'',
                profileId:null,
                startDate:this.pageInfo.startDate,
                endDate:this.pageInfo.endDate,
                portfolioId:'',
                campaignId:'',
                groupsState:'',
                campaignStatus:'',
                targetType:'',
                keyword:'',
                pageSize:'15',
                sort:'',
                sortColumn:'',
                isFavorite:'',
            }
            this.shopID=shopId
            this.marketPlaceID=marId
            this.pageInfo.profileId=id
            this.currency=currency
            // this.getListPage()
            this.getPageInit('currentPage')
        },
        allKeywordlaunch(){
            const arr = []
            this.screen.forEach(item=>{
                this.keywordList.forEach(i=>{
                    arr.push({bid:'',keywordText:i,matchType:item})
                })
            })
            if(this.addInfo.keywordAddReqList.length==0){
                this.addInfo.keywordAddReqList=arr
            }else {
                this.addInfo.keywordAddReqList.forEach(item=>{
                    arr.forEach((i,index)=>{
                        if(item.keywordText==i.keywordText&&item.matchType==i.matchType){
                            arr.splice(index,1)
                        }
                    })
                })
                this.addInfo.keywordAddReqList=[...this.addInfo.keywordAddReqList,...arr]
            }
        },
        getGoodsPageInit(){
            goodsPageInit().then(res=>{
                this.productStatusList=res.data.data.productStatusList
                this.searchList=res.data.data.searchList
            })
        },
        changeDailyBudget(row,index){
                this.$prompt('请输入竞价', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                inputValue:row.defaultBid,
                inputPattern:/^[0-9]+([.]{1}[0-9]+){0,1}$/,
                inputErrorMessage: '只能输入纯数字'
            }).then(({ value }) => {
                this.loading=true
                updateBid({bid:value,ids:[row.adGroupId],type:'adGroups'},this.pageInfo.profileId).then(res=>{
                    this.loading=false
                    if(res.data.code==200){
                        this.$message.success(res.data.message)
                        this.tableData[index].defaultBid=value
                    }else {
                        this.$message.error(res.data.message)
                    }
                })
            }).catch(() => {
                this.$message({
                    type: 'info',
                    message: '取消输入'
                });       
            });
        },
        elaborationClose(){
            this.elaborationBox=false
            this.elaborationInfo={
                asinBrandSameAs:[],
                asinPriceLessThan:'',//最高价
                asinPriceBetween:'',//价格去加
                asinPriceGreaterThan:'',//最低价
                asinReviewRatingBetween:[0,5],//星区间
                asinIsPrimeShippingEligible:''//,派送
            }
        },
        addElaboration(){
            let prime=''
            this.primeTypeList.forEach(item=>{
                if(item.id==this.elaborationInfo.asinIsPrimeShippingEligible) prime=item.value
            })
            // 是否取消加号
            // this.categoriesList.forEach((item,index)=>{
            //     if(this.addInfo.elaborationRow.id==item.id) this.categoriesList[index].operation=1
            // })
            let arr = []
            this.elaborationInfo.asinBrandSameAs.forEach(item=>{
                this.brandList.forEach(i=>{
                    if(i.id==item) arr.push({id:item,name:i.name})
                })
            })
            arr.forEach(item=>{
                
                this.addInfo.targetAddReqList.push({
                    amzAdTargetExpressionList:[{
                        name:this.addInfo.elaborationRow.name,
                        value:this.addInfo.elaborationRow.id,
                        type:this.putType}],
                    bid:'',
                    path:this.addInfo.elaborationRow.path,
                    operation:1,
                    prime:prime,
                    ...this.elaborationInfo,
                    ...item
                })
            })
            this.elaborationClose()
        },
        getGroupDetailInit(id,row){
            this.suggestLoading=true
            this.addInfo.elaborationRow=row
            groupDetailInit({categoryId:id},this.pageInfo.profileId).then(res=>{
                this.suggestLoading=false
                if(res.data.code==200){
                    this.elaborationBox=true
                    this.brandList=res.data.data.brandList
                    this.detailTypeList=res.data.data.detailTypeList
                    this.primeTypeList=res.data.data.primeTypeList
                }
            })
        },
        getTimeSection(val){
            this.pageInfo.startDate=val.value[0]
            this.pageInfo.endDate=val.value[1]
        },
        updateNeKeyword(){
            this.dialogLoading=true
            updateNeKeyword({type:'negativeKeywords',state:'archived',keywordIds:this.archivedNoKeywordsIds},this.pageInfo.profileId).then(res=>{
                this.dialogLoading=false
                if(res.data.code==200){
                    this.$message.success(res.data.message)
                    this.getListNegativeKeywords()
                }else{
                    this.$message.error(res.data.message)
                }
            }).catch(()=>{
                this.dialogLoading=false
                this.$message.error(res.data.message)
            })
        },
        archivedNeTarget(){
            this.dialogLoading=true
            archivedNeTarget({type:'negativeTargets',targetIds:this.archivednoGoodsIds},this.pageInfo.profileId).then(res=>{
                if(res.data.code==200){
                    this.$message.success(res.data.message)
                    this.getListNegativeTargetList()
                }else{
                    this.$message.error(res.data.message)
                }
                this.dialogLoading=false
            }).catch(()=>{
                this.dialogLoading=false
            })
        },
        selectNoKeywords(val){
            this.archivedNoKeywordsIds=val.map(item=>{
                return item.keywordId
            })
        },
        selectNoGoods(val){
            this.archivednoGoodsIds=val.map(item=>{
                return item.targetId
            })
        },
        archivedKeywordSelect(row,id){
            if(row.operation==1){
                return true
            }else {
                return false
            }
        },
        archivedSelect(row,ind){
            if(row.yet==1){
                return true
            }else {
                return false
            }
        },
        getCampaignList(id){
            const arr=[]
            this.campaignList.forEach(item=>{
                if(item.portfolioId==id){
                    arr.push(item)
                }
            })
            this.screenedCampaignList=arr
            this.pageInfo.campaignId=''
        },
        getPortfolioId(portfolioId,id){
            this.pageInfo.portfolioId=portfolioId
            this.pageInfo.campaignId=id
        },
        selectable(row,index){
            if(row.state=='archived'){
                return false
            }else{
                return true
            }
        },
        focusChange(id,status){
            this.loading=true
            if(status==0){
                focusOn(id).then(res=>{
                    this.loading=false
                    if(res.data.code==200){
                        this.getListPage()
                    }else{
                        this.$message.error(res.data.message)
                    }
                })
            }
            if(status==1){
                focusOff(id).then(res=>{
                    this.loading=false
                    if(res.data.code==200){
                        this.getListPage()
                    }else{
                        this.$message.error(res.data.message)
                    }
                })
            }
        },
        gainBidding(index,adGroupId){
            // 获取建议竞价
            this.loading=true
            getGroupSuggestBid(adGroupId,this.pageInfo.profileId).then(res=>{
                this.loading=false
                if(res.data.code==200){
                    if(res.data.data==''){
                        this.$message.warning('暂无建议竞价')
                    }
                    this.$set(this.tableData[index],'suggested',res.data.data.suggested)
                    this.$set(this.tableData[index],'rangeEnd',res.data.data.rangeEnd)
                    this.$set(this.tableData[index],'rangeStart',res.data.data.rangeStart)
                }else {
                    this.$message.error(res.data.message)
                }
            })

        },
        updateGroupBoxClose(){
            this.updateGroupBox=false
            this.addInfo={
                amzAdProductAddReqList:[],
                campaignId:'',
                defaultBid:'',
                keywordAddReqList:[],
                neKeywordAddReqList:[],
                name:'',
                neTargetAddReqList:[],
                targetAddReqList:[],
                targetGroups:[],
                type:'',
            },
            this.serchGoods={
                current:'',
                status:'',
                queryFrom:'advert' 
            }
            this.addNegativeKeywordsInfo={//新增否定关键词信息
                adGroupId:'',
                amzAdKeywordForGroupAddReqList:[],
                campaignId:'',
                profileId:'',
            }
            this.addNeTargetsInfo={
                adGroupId:'',
                amzAdTargetExpressionList:[],
                campaignId:'',
                expressionType:'manual',
            }
            this.selectUpdateGroup='setGroup'
            this.targetsBrandsList=[]
            this.targetsBrandsInfo.keyword=''
        },
        addBoxClose(){
            this.activeName='first'
            this.addBox=false
            this.addInfo={
                amzAdProductAddReqList:[],
                campaignId:'',
                defaultBid:'',
                keywordAddReqList:[],
                neKeywordAddReqList:[],
                name:'',
                neTargetAddReqList:[],
                targetAddReqList:[],
                targetGroups:[],
                type:'',
            }
            this.addedList=[]
            this.AutoTarget=false
            this.keywordList=[]
            this.categoriesList=[]
            this.targetProductList=[]
            this.targetsBrandsInfo.keyword=''
        },
        delNegativeKeyword(index,keywordText){
            this.addInfo.neKeywordAddReqList.splice(index,1)
            this.noKeyList.forEach((item,ind)=>{
                if(item.keywordText==keywordText){
                    this.noKeyList.splice(ind,1)
                }
            })
        },
        delItem(index,name){    
            this.addInfo.neTargetAddReqList.splice(index,1)
            this.targetsBrandsList.forEach(item=>{
                if(this.addInfo.neTargetAddReqList.length==0){
                    item.operation=0
                }else{
                    this.addInfo.neTargetAddReqList.forEach(i=>{
                        if(item.name==i.name&&this.noGoodsType=='asinBrandSameAs'){
                            item.operation=0
                        }
                    })
                }
            })
            this.noGoodsList.forEach((item,ind)=>{
                if(item.name==name){
                    this.noGoodsList.splice(ind,1)
                }
            })
        },
        delAllkeyword(){
            
            if(this.noKeyList.length==0){
                this.$message.warning('只能删除新增的，已存在的可点击归档以达到删除的目的')
            }else {
                this.noKeyList=[]
                this.addInfo.neKeywordAddReqList=this.addInfo.neKeywordAddReqList.filter((item,index)=>{
                    return item.operation==1
                })
            }
        },
        delAllnoGood(){
            if(this.noGoodsList.length==0){
                this.$message.warning('只能删除新增的，已存在的可点击归档以达到删除的目的')
            }else {
                this.addInfo.neTargetAddReqList=this.addInfo.neTargetAddReqList.filter((item,index)=>{
                    return item.yet==1
                })
                this.noGoodsList=[]
            }
        },
        changeStatus(id,state,index){
            // this.batchList=[id]
            // if(state=='enabled'){
            //     this.batchOperation(4)
            // }else if(state=='paused'){
            //     this.batchOperation(3)
            // }
            this.batchInfo.groupIds=[id]
            if(state=='enabled'){
                // this.batchOperation(4)
                this.batchInfo.operateType=4
            }else if(state=='paused'){
                this.batchInfo.operateType=3
            }
            this.loading=true
            batchUpdate(this.batchInfo,this.pageInfo.profileId).then(res=>{
                this.loading=false
                if(res.data.code==200){
                    this.$message.success(res.data.message)
                    this.tableData[index].state=state
                }else {
                    this.$message.error(res.data.message)
                    if(status=='paused'){
                        this.tableData[index].state='enabled'
                    }else{
                        this.tableData[index].state='paused'
                    }
                }
            })

        },
        batchBidding(info){
            if(info=='cancel'){
                this.batchBiddingBox=false
                this.sumInput=''
                return
            }
            if(this.sumType=='percent'){
                this.batchInfo.defaultBidUpdateReq.percent=this.sumInput
            }else{
                this.batchInfo.defaultBidUpdateReq.amount=this.sumInput
            }
            this.batchOperation()
            this.batchBiddingBox=false
        },
        setSumType(type){
            const flag = type.includes('百分比')
            if(flag){
                this.sumType='percent'
            }else{
                this.sumType='amount'
            }
        },
        handleSelectionChange(val){
            this.batchList=val
        },
        batch(id,value){
            if(this.batchList.length==0){
                this.$message.warning('请选择具体内容')
                return
            }
            const arr = []
            this.batchInfo.operateType=id
            this.batchList.forEach(item=>{
                arr.push(item.adGroupId)
            })
            this.batchInfo.groupIds=arr
            if(id==1){
                this.batchBiddingBox=true
            }else{
                this.$confirm(`批量进行${value}操作, 是否继续?`, '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning'
                }).then(() => {
                    this.batchOperation()
                }).catch(() => {
                    this.$message({
                        type: 'info',
                        message: '已取消操作'
                    });          
                });
            }
        },
        batchOperation(){
            this.loading=true
            batchUpdate(this.batchInfo,this.pageInfo.profileId).then(res=>{
                if(res.data.code==200){
                    this.loading=false
                    this.$message.success(res.data.message)
                    this.getListPage()
                }else {
                    this.loading=false
                    this.$message.error(res.data.message)
                }
            })
        },
        toTargetsBrandsList(row,index){
            if(this.noGoods=='' && this.noGoodsType=='asinSameAs'){
                this.$message.warning('请输入内容')
                return ;
            }
            if(this.noGoods=='' && this.noGoodsType=='asinBrandSameAs'){
                this.addInfo.neTargetAddReqList.push({name:row.name,type:this.noGoodsType,value:row.id})
                this.noGoodsList.push({name:row.name,type:this.noGoodsType,value:row.id})
                this.targetsBrandsList[index].operation=1
            }else{
                // this.addInfo.neTargetAddReqList.push({name:this.noGoodsInput,type:this.noGoodsType,value:this.noGoodsInput})
                // this.noGoodsInput=''
                this.addNoGoods()
            }
        },
        DELLnoGoods(){
            this.noGoodsList=[]
            this.addInfo.neTargetAddReqList=[]
            this.getTargetsBrandsList()
        },
        getTargetsBrandsList(){
            this.searchLoading=true
            getTargetsBrands(this.targetsBrandsInfo,this.pageInfo.profileId).then(res=>{
                this.searchLoading=false
                if(res.data.code==200){
                    this.targetsBrandsList=res.data.data.map(item=>{
                        item.operation=0
                        return item
                    })
                    this.addInfo.neTargetAddReqList.forEach(i=>{
                        this.targetsBrandsList.forEach(item=>{
                            if(item.name==i.name&&i.type=='asinBrandSameAs'||item.name==i.value){
                                item.operation=1
                            }
                        })
                    })
                }else {
                    this.$message.error(res.data.message)
                }
            })
        },
        handleClick(tab, event){
            this.putType=tab.name
            if(tab.name=='asinCategorySameAs'){
                this.putTypeInside='first'
            }else{
                this.putTypeInside='second'
            }
        },
        removeAll(){
            this.addInfo.targetAddReqList=[]
            this.targetProductList.forEach(item=>{
                item.operation=0
            })
            this.categoriesList.forEach(item=>{
                item.operation=0
            })
        },
        remove(index,row){
            this.categoriesList.forEach(i=>{
                if(row[index].amzAdTargetExpressionList[0].name==i.name){
                    i.operation=0
                }
            })
            this.targetProductList.forEach(i=>{
                if(row[index].amzAdTargetExpressionList[0].name==i.recommendedTargetAsin){
                    i.operation=0
                }
            })
            this.addInfo.targetAddReqList.splice(index,1)
        },
        selectType(item){
            this.type=item.type//==========================================================选择手自动
            this.addInfo.type=''
            if(item.type=='自'){
                this.addInfo.type='auto'
            }else{
                this.addInfo.type=''
            }
            this.detailActive={
                bid:item.bid,
                date:item.date,
                target:item.target,
                targetingType:item.targetingType,
                top:item.top,
                page:item.page,
                value:item.value
            }
        },
        addLocationGoods(rows){
            rows.operation=1
            if(this.putType=='asinCategorySameAs'){
                this.addInfo.targetAddReqList.push({amzAdTargetExpressionList:[{name:rows.name,value:rows.id,type:this.putType}],bid:'',path:rows.path})
            }
            if(this.putType=='asinSameAs'){
                if(this.inputASIN!=''){
                    this.addInfo.targetAddReqList.push({amzAdTargetExpressionList:[{name:this.inputASIN,type:this.putType,value:this.inputASIN}]})
                }else{
                    this.addInfo.targetAddReqList.push({amzAdTargetExpressionList:[{name:rows.recommendedTargetAsin,type:this.putType,value:rows.recommendedTargetAsin}]})
                }
            }
            this.inputASIN=''
        },
        addKeywords(item){
            const arr = []
            this.screen.forEach(i=>{
                arr.push({bid:'',keywordText:item,matchType:i})
            })
            if(this.addInfo.keywordAddReqList.length==0){
                this.addInfo.keywordAddReqList=arr
            }else{
                this.addInfo.keywordAddReqList.forEach((j,index)=>{
                    arr.forEach((i,ind)=>{
                        if(i.keywordText==j.keywordText && i.matchType==j.matchType){
                            arr.splice(ind,1)
                        }
                    })
                })
                this.addInfo.keywordAddReqList=[...this.addInfo.keywordAddReqList,...arr]
            }
        },
        addInputKeywords(){
            if(this.addKewordInput==''){
                this.$message.warning('请输入关键词')
                return ;
            }
            const arr=[]
            this.screen.forEach(item=>{
                arr.push({bid:'',keywordText:this.addKewordInput,matchType:item})
            })

            if(this.addInfo.keywordAddReqList.length==0){
                this.addInfo.keywordAddReqList=arr
            }else{
                this.addInfo.keywordAddReqList.forEach((j,index)=>{
                    arr.forEach((i,ind)=>{
                        if(i.keywordText==j.keywordText && i.matchType==j.matchType){
                            arr.splice(ind,1)
                        }
                    })
                })
                this.addInfo.keywordAddReqList=[...this.addInfo.keywordAddReqList,...arr]
            }
        },
        handleSizeChange(val){
            this.pageInfo.pageSize=val
            this.getListPage()
        },
        handleCurrentChange(val){
            this.pageInfo.current=val
            this.getListPage()
        },
        getPageInit(val){
            this.loading=true
            pageInit(this.pageInfo.profileId||this.$store.state.profileId?this.pageInfo.profileId||this.$store.state.profileId:0).then(res=>{
                this.loading=false
                if(res.data.code!=200){
                    this.$message.error(res.data.message)
                    return
                }
                this.adGroupStateList=res.data.data.adGroupStateList
                this.adGroupTargetTypeList=res.data.data.adGroupTargetTypeList
                this.adjustBidOperateTypeList=res.data.data.adjustBidOperateTypeList
                this.campaignList=res.data.data.campaignList
                this.campaignStateList=res.data.data.campaignStateList
                this.screenedCampaignList=res.data.data.campaignList
                this.operateTypeList=res.data.data.operateTypeList
                this.portfolioList=res.data.data.portfolioList
                this.shopId=res.data.data.shopId
                this.favoriteTypeList=res.data.data.favoriteTypeList
                this.shopId.forEach(item=>{
                    item.marketPlaceTree.forEach(i=>{
                        if(i.isSelected==1){
                            if(this,this.pageInfo.profileId==null){
                                this.pageInfo.profileId=i.profileId
                                this.currency=i.currency
                                this.shopID=item.id
                                this.marketPlaceID=i.id
                            }
                            this.pageInfo.current=1
                        }
                    })
                })
                if(val=='currentPage'){
                    this.getListPage()
                    return
                }else {
                    let info = this.$route.query
                    if(JSON.stringify(info)!='{}'){
                        if(info.type=='getOne'){
                            this.pageInfo.profileId = Number(info.profileId);
                            this.pageInfo.keyword=info.value
                            console.log(this.pageInfo.profileId,'one');
                            this.getListPage()
                        }else{
                            this.pageInfo.profileId = Number(info.profileId);
                            this.pageInfo.keyword=''
                            this.pageInfo.groupsState=info.state
                            this.screenedCampaignList.forEach(item=>{
                                if(info.campaignId==item.id){
                                    this.pageInfo.campaignId=item.id
                                    this.pageInfo.portfolioId=item.profileId
                                }
                            })
                            console.log(this.pageInfo.profileId,'two');
                            this.getListPage()
                        }
                    }else{
                        this.getListPage()
                    }
                }
            })
        },
        getListPage(){
            this.loading=true
            
            this.$store.commit('setProfileId',this.pageInfo.profileId)
            ListPage(this.pageInfo).then(res=>{
                this.loading=false
                this.total=res.data.pageInfo.total
                this.tableData=res.data.values
            })
        },
        GoodsAll(info){
            if(info=='del'){
                this.addedList.forEach(item=>{
                    item.operation=0
                })
                this.addedList=[]
                this.getGoodsList()
            }else {
                const arr = this.goodsList.filter(item=>{
                    return item.operation==0
                })
                arr.forEach(item=>{
                    item.operation=1
                })
                this.addedList=[...this.addedList,...arr]
            }
        },
        changeCurrent(val){
            this.serchGoods.current=val
            this.getGoodsList()
        },
        manualAdd(){
            // 根据ASIN获取商品
            let list = this.manualASIN.split(/[(\r\n)\r\n]+/)
            getAsinByAsins({asins:list,queryType:this.inputList}).then(res=>{
                if(res.data.code==200){
                    const arr = res.data.data.map(item=>{
                        item.operation=1
                        return item
                    })
                    if(arr.length!=0){
                        this.addedList.forEach(item=>{
                            arr.forEach((i,index)=>{
                                if(item.id==i.id){
                                    arr.splice(index,1)
                                }
                            })
                        })
                    }
                    this.addedList=[...this.addedList,...arr]
                    this.manualASIN=''
                    this.toAdded()
                }
            })
        },
        sure(){
            // 商品
            this.buttonLoading=true
            this.addedList.forEach(item=>{
                this.addInfo.amzAdProductAddReqList.push({asin:item.asin,sku:item.commoditySku})
            })
            console.log(this.addInfo.targetAddReqList);
            
            let list = this.addInfo.targetAddReqList.map(item=>{
                if(item.name==undefined){
                    return item
                }
                if( item.name!=undefined){
                    let postList = []
                    postList.push({
                        name:item.name,
                        type:'asinBrandSameAs',
                        value:item.id
                    })
                    // 区间或价格
                    if(item.asinPriceLessThan!=''&&item.asinPriceGreaterThan!=''){
                        postList.push({
                            name:item.asinPriceGreaterThan+'-'+item.asinPriceLessThan,
                            type:'asinPriceBetween',
                            value:item.asinPriceGreaterThan+'-'+item.asinPriceLessThan,
                        })
                    }else if (item.asinPriceLessThan!=''&&item.asinPriceGreaterThan=='') {
                        postList.push({
                            name:item.asinPriceLessThan,
                            type:'asinPriceLessThan',
                            value:item.asinPriceLessThan,
                        })
                    }else if (item.asinPriceLessThan==''&&item.asinPriceGreaterThan!=''){
                        postList.push({
                            name:item.asinPriceGreaterThan,
                            type:'asinPriceGreaterThan',
                            value:item.asinPriceGreaterThan
                        })
                    }

                    // 分类
                    // 星数
                    if(item.asinReviewRatingBetween!=''){
                        postList.push({
                            name:item.asinReviewRatingBetween[0]+'-'+item.asinReviewRatingBetween[1],
                            type:'asinReviewRatingBetween',
                            value:item.asinReviewRatingBetween[0]+'-'+item.asinReviewRatingBetween[1],
                        })
                    }
                    // 配送
                    if(item.asinIsPrimeShippingEligible!=''){
                        postList.push({
                            name:item.asinIsPrimeShippingEligible,
                            type:'asinIsPrimeShippingEligible',
                            value:item.asinIsPrimeShippingEligible
                        })
                    }
                    if(item.amzAdTargetExpressionList.length!=0){
                        postList.push(item.amzAdTargetExpressionList[0])
                    }
                    // postList={
                    //     amzAdTargetExpressionList:item.amzAdTargetExpressionList,
                    //     bid:item.bid
                    // }
                    return {amzAdTargetExpressionList:postList,bid:item.bid}
                }
            })
            if(!this.AutoTarget){
                this.addInfo.targetGroups=[]
            }else{
                const flag = this.groupAutoTargetType.every(item=>{
                    return item.bid!=''
                })
                if(!flag){
                    this.$message.warning('请指定竞价')
                    return
                }else {
                    this.addInfo.targetGroups=this.groupAutoTargetType.map(item=>{
                        const obj = {
                            bid:item.bid,
                            state:item.state,
                            type:item.type
                        }
                        return obj
                    })
                }
            }
            addGroup({...this.addInfo,targetAddReqList:list},this.pageInfo.profileId).then(res=>{
                this.buttonLoading=false
                if(res.data.code==200){
                    this.$message.success(res.data.message)
                    this.getPageInit()
                    this.addBoxClose()
                    this.$store.commit('setNum')
                }else{
                    this.$message.error(res.data.message)
                    this.addInfo.amzAdProductAddReqList=[]
                    this.addInfo.targetGroups=[]
                    this.addInfo.targetAddReqList.amzAdTargetExpressionList.forEach((item,index)=>{
                        if(index!=0) this.addInfo.targetAddReqList.amzAdTargetExpressionList.splice(index,1)
                    })
                }
            }).catch(()=>{
                this.buttonLoading=false
            })
        },
        activeInfo(){
            this.showActiveInfo=!this.showActiveInfo
        },
        toGoods(item,ind){
            item.operation=0
            this.goodsList.forEach((i,index)=>{
                if(i.id==item.id){
                    this.goodsList[index].operation=0
                }
            })
            this.addedList.splice(ind,1)
            if(this.addedList.length==0){
                this.categoriesList=[]
                this.targetProductList=[]
                this.keywordList=[]
            }else{
                this.toAdded()
            }
        },
        getKeywordsByAsin(arr){
            this.suggestLoading=true
            getKeywordsByAsin(arr,this.pageInfo.profileId).then(res=>{
                this.suggestLoading=false
                this.keywordList=res.data.data
            })
        },
        getTargetProduct(arr){
            this.suggestedLoading=true
            getTargetProduct(arr,this.pageInfo.profileId).then(res=>{
                this.suggestedLoading=false
                this.targetProductList=res.data.data.map(item=>{
                    item.operation=0
                    return item
                })
                if(this.addInfo.targetAddReqList.length!=0){
                    this.addInfo.targetAddReqList.forEach(i=>{
                        this.targetProductList.forEach(j=>{
                            if(j.recommendedTargetAsin==i.amzAdTargetExpressionList[0].name){
                                j.operation=1
                            }
                        })
                    })
                }
            })
        },
        toAdded(item){
            if(item!=undefined){
                this.addedList.push({...item,operation:1})
            }
            this.getGoodsList()
            const arr=[]
            this.addedList.forEach(i=>{
                arr.push(i.asin)
            })
            this.getKeywordsByAsin(arr)
            // getKeywordsByAsin(arr,this.pageInfo.profileId).then(res=>{
            //     this.keywordList=res.data.data
            // })
            getCategories(arr,this.pageInfo.profileId).then(res=>{
                this.categoriesList=res.data.data.map(item=>{
                    item.operation=0
                    return item
                })
                if(this.addInfo.targetAddReqList.length!=0){
                    this.addInfo.targetAddReqList.forEach(i=>{
                        this.categoriesList.forEach(j=>{
                            if(j.name==i.amzAdTargetExpressionList[0].name){
                                j.operation=1
                            }
                        })
                    })
                }
            })
            this.getTargetProduct(arr)
        },
        add(){
            this.addBox=true
            this.asin='asinSameAs'//======================================================要改
            this.getAddInit()
            this.getGoodsPageInit()
            this.getGoodsList()
        },
        addNoKey(){
            if(!this.noKeywords){
                this.$message.warning('请填写关键词')
                return
            }
            const arr = []
            const list=this.noKeywords.split(/[(\r\n)\r\n]+/)
            list.forEach(item=>{
                const flag =  this.addInfo.neKeywordAddReqList.some(i=>{
                    return item==i.keywordText&&this.matchType==i.matchType;
                })
                if(!flag){
                    arr.push({keywordText:item,matchType:this.matchType})
                }
            })
            this.noKeyList=[...this.noKeyList,...arr]
            this.noKeywords=''
            this.addInfo.neKeywordAddReqList=[...this.addInfo.neKeywordAddReqList,...arr]
        },
        delKeywords(index, rows){
            rows.splice(index, 1);
        },
        addNoGoods(){
            if(!this.noGoods){
                this.$message.warning('请填写ASIN')
                return
            }
            const reg = /^B[0-9]{1}[0-9]{1}\w{7}$/
            let list=this.noGoods.split(/[(\r\n)\r\n]+/)
            const arr = []
            list = Array.from(new Set(list))
            let num = 0
            list.forEach(item=>{
                if(!reg.test(item)) num++
            })
            if(num>0){
                this.$message.warning(`有${num}个ASIN格式有误`)
                return
            }
            list.forEach(item=>{
                const flag = this.addInfo.neTargetAddReqList.some(i=>{
                    return item==i.value&&i.type=='asinSameAs'
                })
                if(!flag){
                    arr.push({name:item,value:item,type:'asinSameAs'})
                }
            })
            this.noGoodsList=[...this.noGoodsList,...arr]
            this.noGoods=''
            this.addInfo.neTargetAddReqList=[...this.addInfo.neTargetAddReqList,...arr]
        },
        getAddInit(){
            addInit(this.pageInfo.profileId).then(res=>{
                this.campaignList=res.data.data.campaignList
                this.groupAutoTargetType=res.data.data.groupAutoTargetType.map(item=>{
                    let obj={
                        bid:'2.00',
                        state:'enabled',
                        type:item.id,
                        value:item.value
                    }
                    return obj
                })
            })
        },
        changeBid(item,index){
            if(item.bid>100000){
                this.groupAutoTargetType[index].bid=100000
            }else if (item.bid<2) {
                this.groupAutoTargetType[index].bid=2
            }
        },
        getGoodsList(){
            this.dialogLoading=true
            getGoods({...this.serchGoods,[this.selectAsinType]:this.searchByAsin?this.searchByAsin:null,shopId:this.shopID,marketPlaceId:this.marketPlaceID}).then(res=>{
                this.dialogLoading=false
                const arr = res.data.values.map(item=>{
                    item.operation=0
                    return item
                })
                if(this.addedList.length!=0){
                    const ids=this.addedList.map(item=>{
                        return item.id
                    })
                    arr.forEach(item=>{
                        ids.forEach(i=>{
                            if(i==item.id){
                                item.operation=1
                            }
                        })
                    })
                }
                
                this.goodsList=arr
                this.goodsTotal=res.data.pageInfo.total
            })
        },
        getUpdateInit(id,targetType,campaignId,adGroupId){
            this.addNegativeKeywordsInfo.adGroupId=adGroupId
            this.addNegativeKeywordsInfo.campaignId=campaignId
            this.addNegativeKeywordsInfo.profileId=this.pageInfo.profileId
            this.addNeTargetsInfo.adGroupId=adGroupId
            this.addNeTargetsInfo.campaignId=campaignId
            this.targetType=targetType
            if(targetType=='商品') this.noGoodsType='asinSameAs'
            this.loading=true
            updateInit(id).then(res=>{
                this.loading=false
                if(res.data.code==200){
                    this.updateGroupBox=true
                    this.updateGroupInfo.name=res.data.data.name
                    this.updateGroupInfo.id=res.data.data.id
                    this.updateGroupInfo.state=res.data.data.state
                    this.updateGroupInfo.defaultBid=res.data.data.defaultBid
                }else{
                    this.$message.error(res.data.message)
                }
            })
        },
        // 回显
        getListNegativeTargetList(){
            listNegativeTarget({groupId:this.addNeTargetsInfo.adGroupId}).then(res=>{
                if(res.data.code==200){
                    this.addInfo.neTargetAddReqList=res.data.data.map(item=>{
                        item.yet=1
                        return item
                    })
                }
            })
        },
        getListNegativeKeywords(){
            listNegativeKeywords({groupId:this.addNegativeKeywordsInfo.adGroupId}).then(res=>{
                if(res.data.code==200){
                    // 未封装组件   与添加广告组使用同一个变量  写代码前缺乏思考(￣▽￣)~*
                    this.addInfo.neKeywordAddReqList=res.data.data.map(item=>{
                        item.operation=1
                        return item
                    })
                }else{
                    this.$message.error(res.data.message)
                }
            })
        },
        getListNegativeTarget(tab){
            if(tab.label!=='否定投放') return
            if(this.targetType=='自动'||this.targetType=='商品'){
                this.getListNegativeTargetList()
            }
            if(this.targetType=='自动'||this.targetType=='关键词'){
                this.getListNegativeKeywords()
            }
        },
        update(){
            this.$refs.updateGroupInfo.validate(valid=>{
                if(!valid) return 
                this.buttonLoading=true
                changeGroup(this.updateGroupInfo,this.pageInfo.profileId).then(res=>{
                    this.buttonLoading=false
                    if(res.data.code==200){
                        this.$message.success(res.data.message)
                        this.updateGroupBox=false
                        this.getListPage()
                    }else {
                        this.$message.error(res.data.message)
                    }
                })
            })
        },
        addNegativeKeyword(){
            this.buttonLoading=true
            this.addNegativeKeywordsInfo.amzAdKeywordForGroupAddReqList=this.noKeyList

            addNegativeKeyword(this.addNegativeKeywordsInfo,this.pageInfo.profileId).then(res=>{
                this.buttonLoading=false
                if(res.data.code==200){
                    this.$message.success(res.data.message)
                    this.getListNegativeKeywords()
                    this.noKeyList=[]
                }else {
                    this.$message.error(res.data.message)
                }
            })
        },
        addNeTargets(){
            this.buttonLoading=true
            this.addNeTargetsInfo.amzAdTargetExpressionList=this.noGoodsList
            console.log(this.noGoodsList);
            addNeTargets(this.addNeTargetsInfo,this.pageInfo.profileId).then(res=>{
                this.buttonLoading=false
                if(res.data.code==200){
                    this.$message.success(res.data.message)
                    this.noGoodsList=[]
                    this.getListNegativeTargetList()
                }else{
                    this.$message.error(res.data.message)
                }
            })
        },
        find(){
            this.pageInfo.current=1
            this.getListPage()
        },
        setHead(val){
            this.tableHead=val
        },
        searchGoods(){
            this.serchGoods.current=1
            this.getGoodsList()
        },
        sortTable(column){
            if(column.order=='ascending'){
                this.pageInfo.sort='asc'
            }else if(column.order=='descending'){
                this.pageInfo.sort='desc'
            }else if(column.order==null){
                this.pageInfo.sort=''
                this.pageInfo.sortColumn=''
                this.getListPage()
                return
            }
            this.pageInfo.sortColumn=column.prop
            this.getListPage()
        },
        // 选择时间
        changeTime(val) {
          this.startTime = val.value[0];
          this.endTime = val.value[1];
        },
        getChart(row){
            this.searchkeywordText = row.name;
            this.$store.commit('setAdvName',row.adGroupId)
            this.analysisClick()
        },
        // 经营分析
        analysisClick(val) {
            let params = {
                profileId: this.pageInfo.profileId,
                startDate: val?val.value[0]:'',
                endDate: val?val.value[1]:'',
                groupId: this.$store.state.advName
            }
           listByGroup(params)
             .then(res=> {
               if(res.data.code == 200) {
                 this.ListOfData = res.data.data;
               }else if(res.data.code == 500) {
                 this.$message.error(res.data.message);
               }
           })
          this.drawer = true;
        },
    }
}
</script>

<style scoped lang='scss'>
// ::v-deep .el-switch__core{
//     width:30px!important;
//     height:15px;
//     /*color:#409EFF;*/
//   }
//   /*设置圆*/
// ::v-deep .el-switch__core::after{
//     width:13px;
//     height:13px;
//     margin-top:-1px;
//     margin-bottom: 2px;
// }
.activeInfo{
    width:89%;
    border:1px solid #EEEEEE;
    padding: 16px;
    box-sizing: border-box;
    >div{
        display: inline-block;
        line-height: 35px;
        >span:nth-of-type(1){
            display: inline-block;
            width: 100px;
            text-align: right;
        }
        >span:nth-of-type(2){
            width: 190px;
            display: inline-block;
            color: #999999;
        }
    }
}

.Box{
    display: flex;
    margin: 20px 40px;
    >div{
        overflow: auto;
        flex: 1;
        border: 1px solid #F0F0F0;
        padding: 10px;
        position: relative;
        >.all{
            position: absolute;
            top: 10px;
            right: 10px;
        }
    }
}
.item{
    height: 85px;
    border-bottom:1px solid #F0F0F0;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-right: 10px;
    .pic{
        width: 70px;
        height: 70px;
        margin-right:10px;
        overflow:hidden;
        img{
            width: 100%;
            height: 100%;
        }
    }
    .SKU{
        height: 50px;
        width: 170px;
        line-height: 20px;
        display: flex;
        flex-direction: column;
        justify-content: space-evenly;
    }
    .state{
        display: inline-block;
        width: 24px;
        height: 16px;
        vertical-align: middle;
        margin-top: -4px;
    }
    .realizable{
        width: 120px;
        height: 50px;
        line-height: 20px;
        display: flex;
        flex-direction: column;
        justify-content: space-evenly;
    }
    .price{
        width: 150px;
        height: 60px;
        p{
            line-height: 60px;
        }
    }
}
.words{
    border-left: 1px solid #F0F0F0;
    border-right: 1px solid #F0F0F0;
    margin: 0 40px;
    .keyword{
        display: flex;
        >div{
            flex: 1;
            margin: 10px;
            padding-right:15px;
        }
        >div:nth-of-type(1){
            border-right: 1px solid #F0F0F0;
        }
    }
}
::v-deep .el-collapse-item__content{
    padding-bottom:0 ;
}
.goodsPut{
    border:1px solid #F0F0F0;
    margin: 0 40px;
    padding: 15px;
}
.el-icon-star-off{
    color:grey;
}
.el-icon-star-on{
    color: #F7BA2A;
    font-size: 20px !important;
}
::v-deep .el-input-group__append{
    background: white;
    width:10px;
}
::v-deep .el-input-group__prepend{
    background: white;
    width:10px;
}
.keywordClass {
   margin-top: 20px;
   margin-bottom: 30px;
   left: 150px;
   width: 2550px;
   height: 650px;
   border-radius: 20px;
}
</style>